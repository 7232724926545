
.perspective-section .td-figure {
    perspective: 1500px;
  }
  
  .perspective-section .td-figure img {
    transform: rotateX(10deg) rotateY(-18deg) rotateZ(3deg);
    border-radius: 0.375em;
    box-shadow: 2px 10px 30px hsla(0, 48%, 28%, 0.25);
    transition: .3s;
    width: 100%;
  }
  
  .perspective-section .td-figure:hover img {
    transform: rotate(0);
  }

  .perspective-outer {
    background-color: #1a1a1a;
    width: 100vw;
  }
  
  .perspective-section  {
    width: calc(100% - 3rem);
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  
  .perspective-section .grid {
    display: grid;
    gap: 2rem;
    align-items: center;
  }
  
  .perspective-section .text-component {
    text-align: center;
  }
  
  .perspective-section .text-component h1 {
    margin-bottom: 0.75rem;
  }
  
  @media (min-width: 64rem) {
    .perspective-section .grid {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .perspective-section .text-component {
      text-align: left;
    }
  }