.blogs {
    margin-top: 3rem;
}

.blogs .blog-item {
    background-color: #f4f6f9;
    text-align: left;
    margin-bottom: 10px;
}

.blogs .blog-item>.content {
    color: #23242c;
    position: relative;
    counter-reset: footnotes;
}

.blogs .blog-item>.content .date {
    color: #ffffff;
    background-color: #e947a5;
    font-family: "Roboto Serif", serif;
    width: 6.66em;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    padding: 0.38em 0 1em;
}
.blogs .blog-item>.content .date>.day {
    font-size: 2.66em;
    display: block;
    margin-bottom: 0.15em;
}

.blogs .blog-item>.content .date>.month {
    font-size: 0.866em;
    font-weight: 600;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    display: block;
}

.blogs .blog-item>.content .info {
    margin-left: 6.66em;
    padding: 1.9em 1.7em 1.8em;
    background-color: #fff;
    word-wrap: break-word;
}

.blogs .blog-item>.content .info>.meta {
    font-family: "Sofia Sans", sans-serif;
    margin: 0;
    color: #7f818d;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    clear: both;
}

.blogs .blog-item>.content .info>.meta>.date-info {
    color: #7f818d;
}

.blogs .blog-item>.content .info .title {
    margin: 0.35em 0 0;
    line-height: 1.23em;
    min-height: 2.46em;
    color: #0e0e12;
    font-family: "Roboto Serif", serif;
    font-size: 1.2rem;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.02em;
    outline: 0;
    -webkit-font-smoothing: antialiased;
}