.section-header {
    width: 100%;
    background-color: #021027;
    height: 12rem;
}



.section-header .section-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
    -webkit-mask-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
            mask-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
  }
  
  .circle-container {
    position: absolute;
    transform: translateY(-10vh);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .circle-container .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: screen;
    background-image: radial-gradient(#99ffff, #99ffff 10%, rgba(153, 255, 255, 0) 56%);
    -webkit-animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
            animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
  }
  @-webkit-keyframes fade-frames {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-frames {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes scale-frames {
    0% {
      transform: scale3d(0.4, 0.4, 1);
    }
    50% {
      transform: scale3d(2.2, 2.2, 1);
    }
    100% {
      transform: scale3d(0.4, 0.4, 1);
    }
  }
  @keyframes scale-frames {
    0% {
      transform: scale3d(0.4, 0.4, 1);
    }
    50% {
      transform: scale3d(2.2, 2.2, 1);
    }
    100% {
      transform: scale3d(0.4, 0.4, 1);
    }
  }
  .circle-container:nth-child(1) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-1;
            animation-name: move-frames-1;
    -webkit-animation-duration: 32787ms;
            animation-duration: 32787ms;
    -webkit-animation-delay: 36943ms;
            animation-delay: 36943ms;
  }
  @-webkit-keyframes move-frames-1 {
    from {
      transform: translate3d(95vw, 110vh, 0);
    }
    to {
      transform: translate3d(67vw, -114vh, 0);
    }
  }
  @keyframes move-frames-1 {
    from {
      transform: translate3d(95vw, 110vh, 0);
    }
    to {
      transform: translate3d(67vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(1) .circle {
    -webkit-animation-delay: 699ms;
            animation-delay: 699ms;
  }
  .circle-container:nth-child(2) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-2;
            animation-name: move-frames-2;
    -webkit-animation-duration: 28533ms;
            animation-duration: 28533ms;
    -webkit-animation-delay: 35262ms;
            animation-delay: 35262ms;
  }
  @-webkit-keyframes move-frames-2 {
    from {
      transform: translate3d(98vw, 106vh, 0);
    }
    to {
      transform: translate3d(11vw, -125vh, 0);
    }
  }
  @keyframes move-frames-2 {
    from {
      transform: translate3d(98vw, 106vh, 0);
    }
    to {
      transform: translate3d(11vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(2) .circle {
    -webkit-animation-delay: 248ms;
            animation-delay: 248ms;
  }
  .circle-container:nth-child(3) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-3;
            animation-name: move-frames-3;
    -webkit-animation-duration: 36923ms;
            animation-duration: 36923ms;
    -webkit-animation-delay: 29461ms;
            animation-delay: 29461ms;
  }
  @-webkit-keyframes move-frames-3 {
    from {
      transform: translate3d(35vw, 108vh, 0);
    }
    to {
      transform: translate3d(41vw, -123vh, 0);
    }
  }
  @keyframes move-frames-3 {
    from {
      transform: translate3d(35vw, 108vh, 0);
    }
    to {
      transform: translate3d(41vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(3) .circle {
    -webkit-animation-delay: 165ms;
            animation-delay: 165ms;
  }
  .circle-container:nth-child(4) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-4;
            animation-name: move-frames-4;
    -webkit-animation-duration: 28038ms;
            animation-duration: 28038ms;
    -webkit-animation-delay: 12617ms;
            animation-delay: 12617ms;
  }
  @-webkit-keyframes move-frames-4 {
    from {
      transform: translate3d(23vw, 101vh, 0);
    }
    to {
      transform: translate3d(10vw, -104vh, 0);
    }
  }
  @keyframes move-frames-4 {
    from {
      transform: translate3d(23vw, 101vh, 0);
    }
    to {
      transform: translate3d(10vw, -104vh, 0);
    }
  }
  .circle-container:nth-child(4) .circle {
    -webkit-animation-delay: 1995ms;
            animation-delay: 1995ms;
  }
  .circle-container:nth-child(5) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-5;
            animation-name: move-frames-5;
    -webkit-animation-duration: 34277ms;
            animation-duration: 34277ms;
    -webkit-animation-delay: 17890ms;
            animation-delay: 17890ms;
  }
  @-webkit-keyframes move-frames-5 {
    from {
      transform: translate3d(10vw, 107vh, 0);
    }
    to {
      transform: translate3d(32vw, -122vh, 0);
    }
  }
  @keyframes move-frames-5 {
    from {
      transform: translate3d(10vw, 107vh, 0);
    }
    to {
      transform: translate3d(32vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(5) .circle {
    -webkit-animation-delay: 3395ms;
            animation-delay: 3395ms;
  }
  .circle-container:nth-child(6) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-6;
            animation-name: move-frames-6;
    -webkit-animation-duration: 30260ms;
            animation-duration: 30260ms;
    -webkit-animation-delay: 32759ms;
            animation-delay: 32759ms;
  }
  @-webkit-keyframes move-frames-6 {
    from {
      transform: translate3d(93vw, 109vh, 0);
    }
    to {
      transform: translate3d(95vw, -135vh, 0);
    }
  }
  @keyframes move-frames-6 {
    from {
      transform: translate3d(93vw, 109vh, 0);
    }
    to {
      transform: translate3d(95vw, -135vh, 0);
    }
  }
  .circle-container:nth-child(6) .circle {
    -webkit-animation-delay: 996ms;
            animation-delay: 996ms;
  }
  .circle-container:nth-child(7) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-7;
            animation-name: move-frames-7;
    -webkit-animation-duration: 36987ms;
            animation-duration: 36987ms;
    -webkit-animation-delay: 7765ms;
            animation-delay: 7765ms;
  }
  @-webkit-keyframes move-frames-7 {
    from {
      transform: translate3d(93vw, 103vh, 0);
    }
    to {
      transform: translate3d(87vw, -131vh, 0);
    }
  }
  @keyframes move-frames-7 {
    from {
      transform: translate3d(93vw, 103vh, 0);
    }
    to {
      transform: translate3d(87vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(7) .circle {
    -webkit-animation-delay: 1678ms;
            animation-delay: 1678ms;
  }
  .circle-container:nth-child(8) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-8;
            animation-name: move-frames-8;
    -webkit-animation-duration: 35339ms;
            animation-duration: 35339ms;
    -webkit-animation-delay: 20609ms;
            animation-delay: 20609ms;
  }
  @-webkit-keyframes move-frames-8 {
    from {
      transform: translate3d(12vw, 104vh, 0);
    }
    to {
      transform: translate3d(80vw, -114vh, 0);
    }
  }
  @keyframes move-frames-8 {
    from {
      transform: translate3d(12vw, 104vh, 0);
    }
    to {
      transform: translate3d(80vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(8) .circle {
    -webkit-animation-delay: 2067ms;
            animation-delay: 2067ms;
  }
  .circle-container:nth-child(9) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-9;
            animation-name: move-frames-9;
    -webkit-animation-duration: 33364ms;
            animation-duration: 33364ms;
    -webkit-animation-delay: 8449ms;
            animation-delay: 8449ms;
  }
  @-webkit-keyframes move-frames-9 {
    from {
      transform: translate3d(43vw, 103vh, 0);
    }
    to {
      transform: translate3d(25vw, -106vh, 0);
    }
  }
  @keyframes move-frames-9 {
    from {
      transform: translate3d(43vw, 103vh, 0);
    }
    to {
      transform: translate3d(25vw, -106vh, 0);
    }
  }
  .circle-container:nth-child(9) .circle {
    -webkit-animation-delay: 1689ms;
            animation-delay: 1689ms;
  }
  .circle-container:nth-child(10) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-10;
            animation-name: move-frames-10;
    -webkit-animation-duration: 28844ms;
            animation-duration: 28844ms;
    -webkit-animation-delay: 33808ms;
            animation-delay: 33808ms;
  }
  @-webkit-keyframes move-frames-10 {
    from {
      transform: translate3d(1vw, 107vh, 0);
    }
    to {
      transform: translate3d(34vw, -125vh, 0);
    }
  }
  @keyframes move-frames-10 {
    from {
      transform: translate3d(1vw, 107vh, 0);
    }
    to {
      transform: translate3d(34vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(10) .circle {
    -webkit-animation-delay: 1889ms;
            animation-delay: 1889ms;
  }
  .circle-container:nth-child(11) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-11;
            animation-name: move-frames-11;
    -webkit-animation-duration: 28299ms;
            animation-duration: 28299ms;
    -webkit-animation-delay: 3494ms;
            animation-delay: 3494ms;
  }
  @-webkit-keyframes move-frames-11 {
    from {
      transform: translate3d(58vw, 103vh, 0);
    }
    to {
      transform: translate3d(26vw, -117vh, 0);
    }
  }
  @keyframes move-frames-11 {
    from {
      transform: translate3d(58vw, 103vh, 0);
    }
    to {
      transform: translate3d(26vw, -117vh, 0);
    }
  }
  .circle-container:nth-child(11) .circle {
    -webkit-animation-delay: 1150ms;
            animation-delay: 1150ms;
  }
  .circle-container:nth-child(12) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-12;
            animation-name: move-frames-12;
    -webkit-animation-duration: 31686ms;
            animation-duration: 31686ms;
    -webkit-animation-delay: 26315ms;
            animation-delay: 26315ms;
  }
  @-webkit-keyframes move-frames-12 {
    from {
      transform: translate3d(53vw, 109vh, 0);
    }
    to {
      transform: translate3d(22vw, -135vh, 0);
    }
  }
  @keyframes move-frames-12 {
    from {
      transform: translate3d(53vw, 109vh, 0);
    }
    to {
      transform: translate3d(22vw, -135vh, 0);
    }
  }
  .circle-container:nth-child(12) .circle {
    -webkit-animation-delay: 1434ms;
            animation-delay: 1434ms;
  }
  .circle-container:nth-child(13) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-13;
            animation-name: move-frames-13;
    -webkit-animation-duration: 31911ms;
            animation-duration: 31911ms;
    -webkit-animation-delay: 16972ms;
            animation-delay: 16972ms;
  }
  @-webkit-keyframes move-frames-13 {
    from {
      transform: translate3d(99vw, 106vh, 0);
    }
    to {
      transform: translate3d(32vw, -114vh, 0);
    }
  }
  @keyframes move-frames-13 {
    from {
      transform: translate3d(99vw, 106vh, 0);
    }
    to {
      transform: translate3d(32vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(13) .circle {
    -webkit-animation-delay: 2943ms;
            animation-delay: 2943ms;
  }
  .circle-container:nth-child(14) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-14;
            animation-name: move-frames-14;
    -webkit-animation-duration: 30467ms;
            animation-duration: 30467ms;
    -webkit-animation-delay: 25319ms;
            animation-delay: 25319ms;
  }
  @-webkit-keyframes move-frames-14 {
    from {
      transform: translate3d(63vw, 103vh, 0);
    }
    to {
      transform: translate3d(11vw, -126vh, 0);
    }
  }
  @keyframes move-frames-14 {
    from {
      transform: translate3d(63vw, 103vh, 0);
    }
    to {
      transform: translate3d(11vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(14) .circle {
    -webkit-animation-delay: 3422ms;
            animation-delay: 3422ms;
  }
  .circle-container:nth-child(15) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-15;
            animation-name: move-frames-15;
    -webkit-animation-duration: 35290ms;
            animation-duration: 35290ms;
    -webkit-animation-delay: 35378ms;
            animation-delay: 35378ms;
  }
  @-webkit-keyframes move-frames-15 {
    from {
      transform: translate3d(76vw, 103vh, 0);
    }
    to {
      transform: translate3d(12vw, -119vh, 0);
    }
  }
  @keyframes move-frames-15 {
    from {
      transform: translate3d(76vw, 103vh, 0);
    }
    to {
      transform: translate3d(12vw, -119vh, 0);
    }
  }
  .circle-container:nth-child(15) .circle {
    -webkit-animation-delay: 1915ms;
            animation-delay: 1915ms;
  }
  .circle-container:nth-child(16) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-16;
            animation-name: move-frames-16;
    -webkit-animation-duration: 31269ms;
            animation-duration: 31269ms;
    -webkit-animation-delay: 7132ms;
            animation-delay: 7132ms;
  }
  @-webkit-keyframes move-frames-16 {
    from {
      transform: translate3d(81vw, 109vh, 0);
    }
    to {
      transform: translate3d(80vw, -128vh, 0);
    }
  }
  @keyframes move-frames-16 {
    from {
      transform: translate3d(81vw, 109vh, 0);
    }
    to {
      transform: translate3d(80vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(16) .circle {
    -webkit-animation-delay: 1448ms;
            animation-delay: 1448ms;
  }
  .circle-container:nth-child(17) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-17;
            animation-name: move-frames-17;
    -webkit-animation-duration: 32418ms;
            animation-duration: 32418ms;
    -webkit-animation-delay: 15186ms;
            animation-delay: 15186ms;
  }
  @-webkit-keyframes move-frames-17 {
    from {
      transform: translate3d(77vw, 104vh, 0);
    }
    to {
      transform: translate3d(18vw, -110vh, 0);
    }
  }
  @keyframes move-frames-17 {
    from {
      transform: translate3d(77vw, 104vh, 0);
    }
    to {
      transform: translate3d(18vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(17) .circle {
    -webkit-animation-delay: 2615ms;
            animation-delay: 2615ms;
  }
  .circle-container:nth-child(18) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-18;
            animation-name: move-frames-18;
    -webkit-animation-duration: 34027ms;
            animation-duration: 34027ms;
    -webkit-animation-delay: 5858ms;
            animation-delay: 5858ms;
  }
  @-webkit-keyframes move-frames-18 {
    from {
      transform: translate3d(22vw, 105vh, 0);
    }
    to {
      transform: translate3d(92vw, -133vh, 0);
    }
  }
  @keyframes move-frames-18 {
    from {
      transform: translate3d(22vw, 105vh, 0);
    }
    to {
      transform: translate3d(92vw, -133vh, 0);
    }
  }
  .circle-container:nth-child(18) .circle {
    -webkit-animation-delay: 2158ms;
            animation-delay: 2158ms;
  }
  .circle-container:nth-child(19) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-19;
            animation-name: move-frames-19;
    -webkit-animation-duration: 36355ms;
            animation-duration: 36355ms;
    -webkit-animation-delay: 14780ms;
            animation-delay: 14780ms;
  }
  @-webkit-keyframes move-frames-19 {
    from {
      transform: translate3d(94vw, 108vh, 0);
    }
    to {
      transform: translate3d(96vw, -136vh, 0);
    }
  }
  @keyframes move-frames-19 {
    from {
      transform: translate3d(94vw, 108vh, 0);
    }
    to {
      transform: translate3d(96vw, -136vh, 0);
    }
  }
  .circle-container:nth-child(19) .circle {
    -webkit-animation-delay: 2954ms;
            animation-delay: 2954ms;
  }
  .circle-container:nth-child(20) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-20;
            animation-name: move-frames-20;
    -webkit-animation-duration: 36803ms;
            animation-duration: 36803ms;
    -webkit-animation-delay: 32523ms;
            animation-delay: 32523ms;
  }
  @-webkit-keyframes move-frames-20 {
    from {
      transform: translate3d(45vw, 110vh, 0);
    }
    to {
      transform: translate3d(7vw, -112vh, 0);
    }
  }
  @keyframes move-frames-20 {
    from {
      transform: translate3d(45vw, 110vh, 0);
    }
    to {
      transform: translate3d(7vw, -112vh, 0);
    }
  }
  .circle-container:nth-child(20) .circle {
    -webkit-animation-delay: 626ms;
            animation-delay: 626ms;
  }
  .circle-container:nth-child(21) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-21;
            animation-name: move-frames-21;
    -webkit-animation-duration: 35730ms;
            animation-duration: 35730ms;
    -webkit-animation-delay: 18382ms;
            animation-delay: 18382ms;
  }
  @-webkit-keyframes move-frames-21 {
    from {
      transform: translate3d(71vw, 107vh, 0);
    }
    to {
      transform: translate3d(75vw, -134vh, 0);
    }
  }
  @keyframes move-frames-21 {
    from {
      transform: translate3d(71vw, 107vh, 0);
    }
    to {
      transform: translate3d(75vw, -134vh, 0);
    }
  }
  .circle-container:nth-child(21) .circle {
    -webkit-animation-delay: 3672ms;
            animation-delay: 3672ms;
  }
  .circle-container:nth-child(22) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-22;
            animation-name: move-frames-22;
    -webkit-animation-duration: 36797ms;
            animation-duration: 36797ms;
    -webkit-animation-delay: 20738ms;
            animation-delay: 20738ms;
  }
  @-webkit-keyframes move-frames-22 {
    from {
      transform: translate3d(99vw, 102vh, 0);
    }
    to {
      transform: translate3d(25vw, -125vh, 0);
    }
  }
  @keyframes move-frames-22 {
    from {
      transform: translate3d(99vw, 102vh, 0);
    }
    to {
      transform: translate3d(25vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(22) .circle {
    -webkit-animation-delay: 2923ms;
            animation-delay: 2923ms;
  }
  .circle-container:nth-child(23) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-23;
            animation-name: move-frames-23;
    -webkit-animation-duration: 30881ms;
            animation-duration: 30881ms;
    -webkit-animation-delay: 34606ms;
            animation-delay: 34606ms;
  }
  @-webkit-keyframes move-frames-23 {
    from {
      transform: translate3d(22vw, 103vh, 0);
    }
    to {
      transform: translate3d(40vw, -109vh, 0);
    }
  }
  @keyframes move-frames-23 {
    from {
      transform: translate3d(22vw, 103vh, 0);
    }
    to {
      transform: translate3d(40vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(23) .circle {
    -webkit-animation-delay: 2910ms;
            animation-delay: 2910ms;
  }
  .circle-container:nth-child(24) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-24;
            animation-name: move-frames-24;
    -webkit-animation-duration: 30519ms;
            animation-duration: 30519ms;
    -webkit-animation-delay: 36041ms;
            animation-delay: 36041ms;
  }
  @-webkit-keyframes move-frames-24 {
    from {
      transform: translate3d(39vw, 103vh, 0);
    }
    to {
      transform: translate3d(19vw, -125vh, 0);
    }
  }
  @keyframes move-frames-24 {
    from {
      transform: translate3d(39vw, 103vh, 0);
    }
    to {
      transform: translate3d(19vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(24) .circle {
    -webkit-animation-delay: 2276ms;
            animation-delay: 2276ms;
  }
  .circle-container:nth-child(25) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-25;
            animation-name: move-frames-25;
    -webkit-animation-duration: 35531ms;
            animation-duration: 35531ms;
    -webkit-animation-delay: 22484ms;
            animation-delay: 22484ms;
  }
  @-webkit-keyframes move-frames-25 {
    from {
      transform: translate3d(3vw, 107vh, 0);
    }
    to {
      transform: translate3d(63vw, -122vh, 0);
    }
  }
  @keyframes move-frames-25 {
    from {
      transform: translate3d(3vw, 107vh, 0);
    }
    to {
      transform: translate3d(63vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(25) .circle {
    -webkit-animation-delay: 1267ms;
            animation-delay: 1267ms;
  }
  .circle-container:nth-child(26) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-26;
            animation-name: move-frames-26;
    -webkit-animation-duration: 31113ms;
            animation-duration: 31113ms;
    -webkit-animation-delay: 35550ms;
            animation-delay: 35550ms;
  }
  @-webkit-keyframes move-frames-26 {
    from {
      transform: translate3d(50vw, 105vh, 0);
    }
    to {
      transform: translate3d(7vw, -128vh, 0);
    }
  }
  @keyframes move-frames-26 {
    from {
      transform: translate3d(50vw, 105vh, 0);
    }
    to {
      transform: translate3d(7vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(26) .circle {
    -webkit-animation-delay: 3571ms;
            animation-delay: 3571ms;
  }
  .circle-container:nth-child(27) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-27;
            animation-name: move-frames-27;
    -webkit-animation-duration: 30892ms;
            animation-duration: 30892ms;
    -webkit-animation-delay: 10205ms;
            animation-delay: 10205ms;
  }
  @-webkit-keyframes move-frames-27 {
    from {
      transform: translate3d(57vw, 101vh, 0);
    }
    to {
      transform: translate3d(97vw, -116vh, 0);
    }
  }
  @keyframes move-frames-27 {
    from {
      transform: translate3d(57vw, 101vh, 0);
    }
    to {
      transform: translate3d(97vw, -116vh, 0);
    }
  }
  .circle-container:nth-child(27) .circle {
    -webkit-animation-delay: 3627ms;
            animation-delay: 3627ms;
  }
  .circle-container:nth-child(28) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-28;
            animation-name: move-frames-28;
    -webkit-animation-duration: 36772ms;
            animation-duration: 36772ms;
    -webkit-animation-delay: 36912ms;
            animation-delay: 36912ms;
  }
  @-webkit-keyframes move-frames-28 {
    from {
      transform: translate3d(59vw, 106vh, 0);
    }
    to {
      transform: translate3d(17vw, -123vh, 0);
    }
  }
  @keyframes move-frames-28 {
    from {
      transform: translate3d(59vw, 106vh, 0);
    }
    to {
      transform: translate3d(17vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(28) .circle {
    -webkit-animation-delay: 1103ms;
            animation-delay: 1103ms;
  }
  .circle-container:nth-child(29) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-29;
            animation-name: move-frames-29;
    -webkit-animation-duration: 32170ms;
            animation-duration: 32170ms;
    -webkit-animation-delay: 17610ms;
            animation-delay: 17610ms;
  }
  @-webkit-keyframes move-frames-29 {
    from {
      transform: translate3d(91vw, 108vh, 0);
    }
    to {
      transform: translate3d(96vw, -110vh, 0);
    }
  }
  @keyframes move-frames-29 {
    from {
      transform: translate3d(91vw, 108vh, 0);
    }
    to {
      transform: translate3d(96vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(29) .circle {
    -webkit-animation-delay: 410ms;
            animation-delay: 410ms;
  }
  .circle-container:nth-child(30) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-30;
            animation-name: move-frames-30;
    -webkit-animation-duration: 33715ms;
            animation-duration: 33715ms;
    -webkit-animation-delay: 18621ms;
            animation-delay: 18621ms;
  }
  @-webkit-keyframes move-frames-30 {
    from {
      transform: translate3d(87vw, 104vh, 0);
    }
    to {
      transform: translate3d(87vw, -133vh, 0);
    }
  }
  @keyframes move-frames-30 {
    from {
      transform: translate3d(87vw, 104vh, 0);
    }
    to {
      transform: translate3d(87vw, -133vh, 0);
    }
  }
  .circle-container:nth-child(30) .circle {
    -webkit-animation-delay: 3115ms;
            animation-delay: 3115ms;
  }
  .circle-container:nth-child(31) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-31;
            animation-name: move-frames-31;
    -webkit-animation-duration: 30788ms;
            animation-duration: 30788ms;
    -webkit-animation-delay: 23239ms;
            animation-delay: 23239ms;
  }
  @-webkit-keyframes move-frames-31 {
    from {
      transform: translate3d(54vw, 109vh, 0);
    }
    to {
      transform: translate3d(70vw, -131vh, 0);
    }
  }
  @keyframes move-frames-31 {
    from {
      transform: translate3d(54vw, 109vh, 0);
    }
    to {
      transform: translate3d(70vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(31) .circle {
    -webkit-animation-delay: 2132ms;
            animation-delay: 2132ms;
  }
  .circle-container:nth-child(32) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-32;
            animation-name: move-frames-32;
    -webkit-animation-duration: 31256ms;
            animation-duration: 31256ms;
    -webkit-animation-delay: 20301ms;
            animation-delay: 20301ms;
  }
  @-webkit-keyframes move-frames-32 {
    from {
      transform: translate3d(67vw, 104vh, 0);
    }
    to {
      transform: translate3d(65vw, -128vh, 0);
    }
  }
  @keyframes move-frames-32 {
    from {
      transform: translate3d(67vw, 104vh, 0);
    }
    to {
      transform: translate3d(65vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(32) .circle {
    -webkit-animation-delay: 353ms;
            animation-delay: 353ms;
  }
  .circle-container:nth-child(33) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-33;
            animation-name: move-frames-33;
    -webkit-animation-duration: 34003ms;
            animation-duration: 34003ms;
    -webkit-animation-delay: 16801ms;
            animation-delay: 16801ms;
  }
  @-webkit-keyframes move-frames-33 {
    from {
      transform: translate3d(41vw, 109vh, 0);
    }
    to {
      transform: translate3d(68vw, -117vh, 0);
    }
  }
  @keyframes move-frames-33 {
    from {
      transform: translate3d(41vw, 109vh, 0);
    }
    to {
      transform: translate3d(68vw, -117vh, 0);
    }
  }
  .circle-container:nth-child(33) .circle {
    -webkit-animation-delay: 3852ms;
            animation-delay: 3852ms;
  }
  .circle-container:nth-child(34) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-34;
            animation-name: move-frames-34;
    -webkit-animation-duration: 32940ms;
            animation-duration: 32940ms;
    -webkit-animation-delay: 8348ms;
            animation-delay: 8348ms;
  }
  @-webkit-keyframes move-frames-34 {
    from {
      transform: translate3d(79vw, 106vh, 0);
    }
    to {
      transform: translate3d(61vw, -121vh, 0);
    }
  }
  @keyframes move-frames-34 {
    from {
      transform: translate3d(79vw, 106vh, 0);
    }
    to {
      transform: translate3d(61vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(34) .circle {
    -webkit-animation-delay: 1569ms;
            animation-delay: 1569ms;
  }
  .circle-container:nth-child(35) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-35;
            animation-name: move-frames-35;
    -webkit-animation-duration: 33476ms;
            animation-duration: 33476ms;
    -webkit-animation-delay: 20167ms;
            animation-delay: 20167ms;
  }
  @-webkit-keyframes move-frames-35 {
    from {
      transform: translate3d(51vw, 109vh, 0);
    }
    to {
      transform: translate3d(13vw, -122vh, 0);
    }
  }
  @keyframes move-frames-35 {
    from {
      transform: translate3d(51vw, 109vh, 0);
    }
    to {
      transform: translate3d(13vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(35) .circle {
    -webkit-animation-delay: 3976ms;
            animation-delay: 3976ms;
  }
  .circle-container:nth-child(36) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-36;
            animation-name: move-frames-36;
    -webkit-animation-duration: 33252ms;
            animation-duration: 33252ms;
    -webkit-animation-delay: 10813ms;
            animation-delay: 10813ms;
  }
  @-webkit-keyframes move-frames-36 {
    from {
      transform: translate3d(30vw, 107vh, 0);
    }
    to {
      transform: translate3d(39vw, -121vh, 0);
    }
  }
  @keyframes move-frames-36 {
    from {
      transform: translate3d(30vw, 107vh, 0);
    }
    to {
      transform: translate3d(39vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(36) .circle {
    -webkit-animation-delay: 24ms;
            animation-delay: 24ms;
  }
  .circle-container:nth-child(37) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-37;
            animation-name: move-frames-37;
    -webkit-animation-duration: 32896ms;
            animation-duration: 32896ms;
    -webkit-animation-delay: 19836ms;
            animation-delay: 19836ms;
  }
  @-webkit-keyframes move-frames-37 {
    from {
      transform: translate3d(32vw, 107vh, 0);
    }
    to {
      transform: translate3d(88vw, -127vh, 0);
    }
  }
  @keyframes move-frames-37 {
    from {
      transform: translate3d(32vw, 107vh, 0);
    }
    to {
      transform: translate3d(88vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(37) .circle {
    -webkit-animation-delay: 1461ms;
            animation-delay: 1461ms;
  }
  .circle-container:nth-child(38) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-38;
            animation-name: move-frames-38;
    -webkit-animation-duration: 35170ms;
            animation-duration: 35170ms;
    -webkit-animation-delay: 18798ms;
            animation-delay: 18798ms;
  }
  @-webkit-keyframes move-frames-38 {
    from {
      transform: translate3d(28vw, 101vh, 0);
    }
    to {
      transform: translate3d(78vw, -114vh, 0);
    }
  }
  @keyframes move-frames-38 {
    from {
      transform: translate3d(28vw, 101vh, 0);
    }
    to {
      transform: translate3d(78vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(38) .circle {
    -webkit-animation-delay: 2880ms;
            animation-delay: 2880ms;
  }
  .circle-container:nth-child(39) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-39;
            animation-name: move-frames-39;
    -webkit-animation-duration: 34262ms;
            animation-duration: 34262ms;
    -webkit-animation-delay: 10989ms;
            animation-delay: 10989ms;
  }
  @-webkit-keyframes move-frames-39 {
    from {
      transform: translate3d(49vw, 108vh, 0);
    }
    to {
      transform: translate3d(46vw, -137vh, 0);
    }
  }
  @keyframes move-frames-39 {
    from {
      transform: translate3d(49vw, 108vh, 0);
    }
    to {
      transform: translate3d(46vw, -137vh, 0);
    }
  }
  .circle-container:nth-child(39) .circle {
    -webkit-animation-delay: 3325ms;
            animation-delay: 3325ms;
  }
  .circle-container:nth-child(40) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-40;
            animation-name: move-frames-40;
    -webkit-animation-duration: 30670ms;
            animation-duration: 30670ms;
    -webkit-animation-delay: 17241ms;
            animation-delay: 17241ms;
  }
  @-webkit-keyframes move-frames-40 {
    from {
      transform: translate3d(26vw, 102vh, 0);
    }
    to {
      transform: translate3d(58vw, -109vh, 0);
    }
  }
  @keyframes move-frames-40 {
    from {
      transform: translate3d(26vw, 102vh, 0);
    }
    to {
      transform: translate3d(58vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(40) .circle {
    -webkit-animation-delay: 1440ms;
            animation-delay: 1440ms;
  }
  .circle-container:nth-child(41) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-41;
            animation-name: move-frames-41;
    -webkit-animation-duration: 32245ms;
            animation-duration: 32245ms;
    -webkit-animation-delay: 2950ms;
            animation-delay: 2950ms;
  }
  @-webkit-keyframes move-frames-41 {
    from {
      transform: translate3d(99vw, 107vh, 0);
    }
    to {
      transform: translate3d(49vw, -127vh, 0);
    }
  }
  @keyframes move-frames-41 {
    from {
      transform: translate3d(99vw, 107vh, 0);
    }
    to {
      transform: translate3d(49vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(41) .circle {
    -webkit-animation-delay: 869ms;
            animation-delay: 869ms;
  }
  .circle-container:nth-child(42) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-42;
            animation-name: move-frames-42;
    -webkit-animation-duration: 30103ms;
            animation-duration: 30103ms;
    -webkit-animation-delay: 17674ms;
            animation-delay: 17674ms;
  }
  @-webkit-keyframes move-frames-42 {
    from {
      transform: translate3d(57vw, 102vh, 0);
    }
    to {
      transform: translate3d(7vw, -126vh, 0);
    }
  }
  @keyframes move-frames-42 {
    from {
      transform: translate3d(57vw, 102vh, 0);
    }
    to {
      transform: translate3d(7vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(42) .circle {
    -webkit-animation-delay: 3186ms;
            animation-delay: 3186ms;
  }
  .circle-container:nth-child(43) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-43;
            animation-name: move-frames-43;
    -webkit-animation-duration: 33286ms;
            animation-duration: 33286ms;
    -webkit-animation-delay: 9375ms;
            animation-delay: 9375ms;
  }
  @-webkit-keyframes move-frames-43 {
    from {
      transform: translate3d(38vw, 105vh, 0);
    }
    to {
      transform: translate3d(86vw, -121vh, 0);
    }
  }
  @keyframes move-frames-43 {
    from {
      transform: translate3d(38vw, 105vh, 0);
    }
    to {
      transform: translate3d(86vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(43) .circle {
    -webkit-animation-delay: 1388ms;
            animation-delay: 1388ms;
  }
  .circle-container:nth-child(44) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-44;
            animation-name: move-frames-44;
    -webkit-animation-duration: 28912ms;
            animation-duration: 28912ms;
    -webkit-animation-delay: 27907ms;
            animation-delay: 27907ms;
  }
  @-webkit-keyframes move-frames-44 {
    from {
      transform: translate3d(83vw, 106vh, 0);
    }
    to {
      transform: translate3d(29vw, -110vh, 0);
    }
  }
  @keyframes move-frames-44 {
    from {
      transform: translate3d(83vw, 106vh, 0);
    }
    to {
      transform: translate3d(29vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(44) .circle {
    -webkit-animation-delay: 1729ms;
            animation-delay: 1729ms;
  }
  .circle-container:nth-child(45) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-45;
            animation-name: move-frames-45;
    -webkit-animation-duration: 34457ms;
            animation-duration: 34457ms;
    -webkit-animation-delay: 17335ms;
            animation-delay: 17335ms;
  }
  @-webkit-keyframes move-frames-45 {
    from {
      transform: translate3d(75vw, 103vh, 0);
    }
    to {
      transform: translate3d(74vw, -104vh, 0);
    }
  }
  @keyframes move-frames-45 {
    from {
      transform: translate3d(75vw, 103vh, 0);
    }
    to {
      transform: translate3d(74vw, -104vh, 0);
    }
  }
  .circle-container:nth-child(45) .circle {
    -webkit-animation-delay: 671ms;
            animation-delay: 671ms;
  }
  .circle-container:nth-child(46) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-46;
            animation-name: move-frames-46;
    -webkit-animation-duration: 29018ms;
            animation-duration: 29018ms;
    -webkit-animation-delay: 14757ms;
            animation-delay: 14757ms;
  }
  @-webkit-keyframes move-frames-46 {
    from {
      transform: translate3d(48vw, 105vh, 0);
    }
    to {
      transform: translate3d(60vw, -131vh, 0);
    }
  }
  @keyframes move-frames-46 {
    from {
      transform: translate3d(48vw, 105vh, 0);
    }
    to {
      transform: translate3d(60vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(46) .circle {
    -webkit-animation-delay: 3917ms;
            animation-delay: 3917ms;
  }
  .circle-container:nth-child(47) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-47;
            animation-name: move-frames-47;
    -webkit-animation-duration: 34271ms;
            animation-duration: 34271ms;
    -webkit-animation-delay: 29566ms;
            animation-delay: 29566ms;
  }
  @-webkit-keyframes move-frames-47 {
    from {
      transform: translate3d(7vw, 110vh, 0);
    }
    to {
      transform: translate3d(29vw, -134vh, 0);
    }
  }
  @keyframes move-frames-47 {
    from {
      transform: translate3d(7vw, 110vh, 0);
    }
    to {
      transform: translate3d(29vw, -134vh, 0);
    }
  }
  .circle-container:nth-child(47) .circle {
    -webkit-animation-delay: 3319ms;
            animation-delay: 3319ms;
  }
  .circle-container:nth-child(48) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-48;
            animation-name: move-frames-48;
    -webkit-animation-duration: 35406ms;
            animation-duration: 35406ms;
    -webkit-animation-delay: 3257ms;
            animation-delay: 3257ms;
  }
  @-webkit-keyframes move-frames-48 {
    from {
      transform: translate3d(39vw, 101vh, 0);
    }
    to {
      transform: translate3d(36vw, -118vh, 0);
    }
  }
  @keyframes move-frames-48 {
    from {
      transform: translate3d(39vw, 101vh, 0);
    }
    to {
      transform: translate3d(36vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(48) .circle {
    -webkit-animation-delay: 3699ms;
            animation-delay: 3699ms;
  }
  .circle-container:nth-child(49) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-49;
            animation-name: move-frames-49;
    -webkit-animation-duration: 29398ms;
            animation-duration: 29398ms;
    -webkit-animation-delay: 33108ms;
            animation-delay: 33108ms;
  }
  @-webkit-keyframes move-frames-49 {
    from {
      transform: translate3d(29vw, 107vh, 0);
    }
    to {
      transform: translate3d(34vw, -134vh, 0);
    }
  }
  @keyframes move-frames-49 {
    from {
      transform: translate3d(29vw, 107vh, 0);
    }
    to {
      transform: translate3d(34vw, -134vh, 0);
    }
  }
  .circle-container:nth-child(49) .circle {
    -webkit-animation-delay: 410ms;
            animation-delay: 410ms;
  }
  .circle-container:nth-child(50) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-50;
            animation-name: move-frames-50;
    -webkit-animation-duration: 32225ms;
            animation-duration: 32225ms;
    -webkit-animation-delay: 31782ms;
            animation-delay: 31782ms;
  }
  @-webkit-keyframes move-frames-50 {
    from {
      transform: translate3d(78vw, 106vh, 0);
    }
    to {
      transform: translate3d(62vw, -135vh, 0);
    }
  }
  @keyframes move-frames-50 {
    from {
      transform: translate3d(78vw, 106vh, 0);
    }
    to {
      transform: translate3d(62vw, -135vh, 0);
    }
  }
  .circle-container:nth-child(50) .circle {
    -webkit-animation-delay: 3927ms;
            animation-delay: 3927ms;
  }
  .circle-container:nth-child(51) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-51;
            animation-name: move-frames-51;
    -webkit-animation-duration: 35207ms;
            animation-duration: 35207ms;
    -webkit-animation-delay: 24554ms;
            animation-delay: 24554ms;
  }
  @-webkit-keyframes move-frames-51 {
    from {
      transform: translate3d(7vw, 106vh, 0);
    }
    to {
      transform: translate3d(28vw, -126vh, 0);
    }
  }
  @keyframes move-frames-51 {
    from {
      transform: translate3d(7vw, 106vh, 0);
    }
    to {
      transform: translate3d(28vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(51) .circle {
    -webkit-animation-delay: 2694ms;
            animation-delay: 2694ms;
  }
  .circle-container:nth-child(52) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-52;
            animation-name: move-frames-52;
    -webkit-animation-duration: 29807ms;
            animation-duration: 29807ms;
    -webkit-animation-delay: 21118ms;
            animation-delay: 21118ms;
  }
  @-webkit-keyframes move-frames-52 {
    from {
      transform: translate3d(93vw, 106vh, 0);
    }
    to {
      transform: translate3d(86vw, -108vh, 0);
    }
  }
  @keyframes move-frames-52 {
    from {
      transform: translate3d(93vw, 106vh, 0);
    }
    to {
      transform: translate3d(86vw, -108vh, 0);
    }
  }
  .circle-container:nth-child(52) .circle {
    -webkit-animation-delay: 964ms;
            animation-delay: 964ms;
  }
  .circle-container:nth-child(53) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-53;
            animation-name: move-frames-53;
    -webkit-animation-duration: 33342ms;
            animation-duration: 33342ms;
    -webkit-animation-delay: 119ms;
            animation-delay: 119ms;
  }
  @-webkit-keyframes move-frames-53 {
    from {
      transform: translate3d(28vw, 104vh, 0);
    }
    to {
      transform: translate3d(13vw, -134vh, 0);
    }
  }
  @keyframes move-frames-53 {
    from {
      transform: translate3d(28vw, 104vh, 0);
    }
    to {
      transform: translate3d(13vw, -134vh, 0);
    }
  }
  .circle-container:nth-child(53) .circle {
    -webkit-animation-delay: 37ms;
            animation-delay: 37ms;
  }
  .circle-container:nth-child(54) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-54;
            animation-name: move-frames-54;
    -webkit-animation-duration: 36722ms;
            animation-duration: 36722ms;
    -webkit-animation-delay: 16130ms;
            animation-delay: 16130ms;
  }
  @-webkit-keyframes move-frames-54 {
    from {
      transform: translate3d(2vw, 105vh, 0);
    }
    to {
      transform: translate3d(52vw, -115vh, 0);
    }
  }
  @keyframes move-frames-54 {
    from {
      transform: translate3d(2vw, 105vh, 0);
    }
    to {
      transform: translate3d(52vw, -115vh, 0);
    }
  }
  .circle-container:nth-child(54) .circle {
    -webkit-animation-delay: 3517ms;
            animation-delay: 3517ms;
  }
  .circle-container:nth-child(55) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-55;
            animation-name: move-frames-55;
    -webkit-animation-duration: 30122ms;
            animation-duration: 30122ms;
    -webkit-animation-delay: 19595ms;
            animation-delay: 19595ms;
  }
  @-webkit-keyframes move-frames-55 {
    from {
      transform: translate3d(25vw, 107vh, 0);
    }
    to {
      transform: translate3d(95vw, -126vh, 0);
    }
  }
  @keyframes move-frames-55 {
    from {
      transform: translate3d(25vw, 107vh, 0);
    }
    to {
      transform: translate3d(95vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(55) .circle {
    -webkit-animation-delay: 1732ms;
            animation-delay: 1732ms;
  }
  .circle-container:nth-child(56) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-56;
            animation-name: move-frames-56;
    -webkit-animation-duration: 31411ms;
            animation-duration: 31411ms;
    -webkit-animation-delay: 28612ms;
            animation-delay: 28612ms;
  }
  @-webkit-keyframes move-frames-56 {
    from {
      transform: translate3d(91vw, 109vh, 0);
    }
    to {
      transform: translate3d(7vw, -110vh, 0);
    }
  }
  @keyframes move-frames-56 {
    from {
      transform: translate3d(91vw, 109vh, 0);
    }
    to {
      transform: translate3d(7vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(56) .circle {
    -webkit-animation-delay: 239ms;
            animation-delay: 239ms;
  }
  .circle-container:nth-child(57) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-57;
            animation-name: move-frames-57;
    -webkit-animation-duration: 30570ms;
            animation-duration: 30570ms;
    -webkit-animation-delay: 3664ms;
            animation-delay: 3664ms;
  }
  @-webkit-keyframes move-frames-57 {
    from {
      transform: translate3d(86vw, 107vh, 0);
    }
    to {
      transform: translate3d(78vw, -113vh, 0);
    }
  }
  @keyframes move-frames-57 {
    from {
      transform: translate3d(86vw, 107vh, 0);
    }
    to {
      transform: translate3d(78vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(57) .circle {
    -webkit-animation-delay: 551ms;
            animation-delay: 551ms;
  }
  .circle-container:nth-child(58) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-58;
            animation-name: move-frames-58;
    -webkit-animation-duration: 30804ms;
            animation-duration: 30804ms;
    -webkit-animation-delay: 13283ms;
            animation-delay: 13283ms;
  }
  @-webkit-keyframes move-frames-58 {
    from {
      transform: translate3d(19vw, 101vh, 0);
    }
    to {
      transform: translate3d(52vw, -130vh, 0);
    }
  }
  @keyframes move-frames-58 {
    from {
      transform: translate3d(19vw, 101vh, 0);
    }
    to {
      transform: translate3d(52vw, -130vh, 0);
    }
  }
  .circle-container:nth-child(58) .circle {
    -webkit-animation-delay: 1992ms;
            animation-delay: 1992ms;
  }
  .circle-container:nth-child(59) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-59;
            animation-name: move-frames-59;
    -webkit-animation-duration: 36117ms;
            animation-duration: 36117ms;
    -webkit-animation-delay: 32613ms;
            animation-delay: 32613ms;
  }
  @-webkit-keyframes move-frames-59 {
    from {
      transform: translate3d(62vw, 107vh, 0);
    }
    to {
      transform: translate3d(32vw, -136vh, 0);
    }
  }
  @keyframes move-frames-59 {
    from {
      transform: translate3d(62vw, 107vh, 0);
    }
    to {
      transform: translate3d(32vw, -136vh, 0);
    }
  }
  .circle-container:nth-child(59) .circle {
    -webkit-animation-delay: 2578ms;
            animation-delay: 2578ms;
  }
  .circle-container:nth-child(60) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-60;
            animation-name: move-frames-60;
    -webkit-animation-duration: 28337ms;
            animation-duration: 28337ms;
    -webkit-animation-delay: 21443ms;
            animation-delay: 21443ms;
  }
  @-webkit-keyframes move-frames-60 {
    from {
      transform: translate3d(18vw, 104vh, 0);
    }
    to {
      transform: translate3d(91vw, -113vh, 0);
    }
  }
  @keyframes move-frames-60 {
    from {
      transform: translate3d(18vw, 104vh, 0);
    }
    to {
      transform: translate3d(91vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(60) .circle {
    -webkit-animation-delay: 2390ms;
            animation-delay: 2390ms;
  }
  .circle-container:nth-child(61) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-61;
            animation-name: move-frames-61;
    -webkit-animation-duration: 35833ms;
            animation-duration: 35833ms;
    -webkit-animation-delay: 27107ms;
            animation-delay: 27107ms;
  }
  @-webkit-keyframes move-frames-61 {
    from {
      transform: translate3d(58vw, 103vh, 0);
    }
    to {
      transform: translate3d(74vw, -122vh, 0);
    }
  }
  @keyframes move-frames-61 {
    from {
      transform: translate3d(58vw, 103vh, 0);
    }
    to {
      transform: translate3d(74vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(61) .circle {
    -webkit-animation-delay: 3375ms;
            animation-delay: 3375ms;
  }
  .circle-container:nth-child(62) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-62;
            animation-name: move-frames-62;
    -webkit-animation-duration: 35624ms;
            animation-duration: 35624ms;
    -webkit-animation-delay: 34650ms;
            animation-delay: 34650ms;
  }
  @-webkit-keyframes move-frames-62 {
    from {
      transform: translate3d(2vw, 107vh, 0);
    }
    to {
      transform: translate3d(25vw, -118vh, 0);
    }
  }
  @keyframes move-frames-62 {
    from {
      transform: translate3d(2vw, 107vh, 0);
    }
    to {
      transform: translate3d(25vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(62) .circle {
    -webkit-animation-delay: 2887ms;
            animation-delay: 2887ms;
  }
  .circle-container:nth-child(63) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-63;
            animation-name: move-frames-63;
    -webkit-animation-duration: 33510ms;
            animation-duration: 33510ms;
    -webkit-animation-delay: 20696ms;
            animation-delay: 20696ms;
  }
  @-webkit-keyframes move-frames-63 {
    from {
      transform: translate3d(4vw, 102vh, 0);
    }
    to {
      transform: translate3d(57vw, -117vh, 0);
    }
  }
  @keyframes move-frames-63 {
    from {
      transform: translate3d(4vw, 102vh, 0);
    }
    to {
      transform: translate3d(57vw, -117vh, 0);
    }
  }
  .circle-container:nth-child(63) .circle {
    -webkit-animation-delay: 3473ms;
            animation-delay: 3473ms;
  }
  .circle-container:nth-child(64) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-64;
            animation-name: move-frames-64;
    -webkit-animation-duration: 31753ms;
            animation-duration: 31753ms;
    -webkit-animation-delay: 25107ms;
            animation-delay: 25107ms;
  }
  @-webkit-keyframes move-frames-64 {
    from {
      transform: translate3d(30vw, 109vh, 0);
    }
    to {
      transform: translate3d(87vw, -116vh, 0);
    }
  }
  @keyframes move-frames-64 {
    from {
      transform: translate3d(30vw, 109vh, 0);
    }
    to {
      transform: translate3d(87vw, -116vh, 0);
    }
  }
  .circle-container:nth-child(64) .circle {
    -webkit-animation-delay: 1646ms;
            animation-delay: 1646ms;
  }
  .circle-container:nth-child(65) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-65;
            animation-name: move-frames-65;
    -webkit-animation-duration: 31832ms;
            animation-duration: 31832ms;
    -webkit-animation-delay: 29536ms;
            animation-delay: 29536ms;
  }
  @-webkit-keyframes move-frames-65 {
    from {
      transform: translate3d(55vw, 109vh, 0);
    }
    to {
      transform: translate3d(89vw, -129vh, 0);
    }
  }
  @keyframes move-frames-65 {
    from {
      transform: translate3d(55vw, 109vh, 0);
    }
    to {
      transform: translate3d(89vw, -129vh, 0);
    }
  }
  .circle-container:nth-child(65) .circle {
    -webkit-animation-delay: 32ms;
            animation-delay: 32ms;
  }
  .circle-container:nth-child(66) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-66;
            animation-name: move-frames-66;
    -webkit-animation-duration: 33140ms;
            animation-duration: 33140ms;
    -webkit-animation-delay: 25106ms;
            animation-delay: 25106ms;
  }
  @-webkit-keyframes move-frames-66 {
    from {
      transform: translate3d(80vw, 106vh, 0);
    }
    to {
      transform: translate3d(18vw, -129vh, 0);
    }
  }
  @keyframes move-frames-66 {
    from {
      transform: translate3d(80vw, 106vh, 0);
    }
    to {
      transform: translate3d(18vw, -129vh, 0);
    }
  }
  .circle-container:nth-child(66) .circle {
    -webkit-animation-delay: 3578ms;
            animation-delay: 3578ms;
  }
  .circle-container:nth-child(67) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-67;
            animation-name: move-frames-67;
    -webkit-animation-duration: 31735ms;
            animation-duration: 31735ms;
    -webkit-animation-delay: 36159ms;
            animation-delay: 36159ms;
  }
  @-webkit-keyframes move-frames-67 {
    from {
      transform: translate3d(33vw, 103vh, 0);
    }
    to {
      transform: translate3d(13vw, -110vh, 0);
    }
  }
  @keyframes move-frames-67 {
    from {
      transform: translate3d(33vw, 103vh, 0);
    }
    to {
      transform: translate3d(13vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(67) .circle {
    -webkit-animation-delay: 322ms;
            animation-delay: 322ms;
  }
  .circle-container:nth-child(68) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-68;
            animation-name: move-frames-68;
    -webkit-animation-duration: 34316ms;
            animation-duration: 34316ms;
    -webkit-animation-delay: 21547ms;
            animation-delay: 21547ms;
  }
  @-webkit-keyframes move-frames-68 {
    from {
      transform: translate3d(38vw, 106vh, 0);
    }
    to {
      transform: translate3d(63vw, -114vh, 0);
    }
  }
  @keyframes move-frames-68 {
    from {
      transform: translate3d(38vw, 106vh, 0);
    }
    to {
      transform: translate3d(63vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(68) .circle {
    -webkit-animation-delay: 1725ms;
            animation-delay: 1725ms;
  }
  .circle-container:nth-child(69) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-69;
            animation-name: move-frames-69;
    -webkit-animation-duration: 29154ms;
            animation-duration: 29154ms;
    -webkit-animation-delay: 778ms;
            animation-delay: 778ms;
  }
  @-webkit-keyframes move-frames-69 {
    from {
      transform: translate3d(82vw, 103vh, 0);
    }
    to {
      transform: translate3d(31vw, -123vh, 0);
    }
  }
  @keyframes move-frames-69 {
    from {
      transform: translate3d(82vw, 103vh, 0);
    }
    to {
      transform: translate3d(31vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(69) .circle {
    -webkit-animation-delay: 3091ms;
            animation-delay: 3091ms;
  }
  .circle-container:nth-child(70) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-70;
            animation-name: move-frames-70;
    -webkit-animation-duration: 35670ms;
            animation-duration: 35670ms;
    -webkit-animation-delay: 13003ms;
            animation-delay: 13003ms;
  }
  @-webkit-keyframes move-frames-70 {
    from {
      transform: translate3d(6vw, 110vh, 0);
    }
    to {
      transform: translate3d(72vw, -118vh, 0);
    }
  }
  @keyframes move-frames-70 {
    from {
      transform: translate3d(6vw, 110vh, 0);
    }
    to {
      transform: translate3d(72vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(70) .circle {
    -webkit-animation-delay: 683ms;
            animation-delay: 683ms;
  }
  .circle-container:nth-child(71) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-71;
            animation-name: move-frames-71;
    -webkit-animation-duration: 29210ms;
            animation-duration: 29210ms;
    -webkit-animation-delay: 3433ms;
            animation-delay: 3433ms;
  }
  @-webkit-keyframes move-frames-71 {
    from {
      transform: translate3d(51vw, 106vh, 0);
    }
    to {
      transform: translate3d(94vw, -127vh, 0);
    }
  }
  @keyframes move-frames-71 {
    from {
      transform: translate3d(51vw, 106vh, 0);
    }
    to {
      transform: translate3d(94vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(71) .circle {
    -webkit-animation-delay: 2282ms;
            animation-delay: 2282ms;
  }
  .circle-container:nth-child(72) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-72;
            animation-name: move-frames-72;
    -webkit-animation-duration: 33435ms;
            animation-duration: 33435ms;
    -webkit-animation-delay: 20242ms;
            animation-delay: 20242ms;
  }
  @-webkit-keyframes move-frames-72 {
    from {
      transform: translate3d(69vw, 110vh, 0);
    }
    to {
      transform: translate3d(91vw, -127vh, 0);
    }
  }
  @keyframes move-frames-72 {
    from {
      transform: translate3d(69vw, 110vh, 0);
    }
    to {
      transform: translate3d(91vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(72) .circle {
    -webkit-animation-delay: 499ms;
            animation-delay: 499ms;
  }
  .circle-container:nth-child(73) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-73;
            animation-name: move-frames-73;
    -webkit-animation-duration: 36121ms;
            animation-duration: 36121ms;
    -webkit-animation-delay: 8320ms;
            animation-delay: 8320ms;
  }
  @-webkit-keyframes move-frames-73 {
    from {
      transform: translate3d(39vw, 103vh, 0);
    }
    to {
      transform: translate3d(3vw, -116vh, 0);
    }
  }
  @keyframes move-frames-73 {
    from {
      transform: translate3d(39vw, 103vh, 0);
    }
    to {
      transform: translate3d(3vw, -116vh, 0);
    }
  }
  .circle-container:nth-child(73) .circle {
    -webkit-animation-delay: 1552ms;
            animation-delay: 1552ms;
  }
  .circle-container:nth-child(74) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-74;
            animation-name: move-frames-74;
    -webkit-animation-duration: 35003ms;
            animation-duration: 35003ms;
    -webkit-animation-delay: 29140ms;
            animation-delay: 29140ms;
  }
  @-webkit-keyframes move-frames-74 {
    from {
      transform: translate3d(22vw, 108vh, 0);
    }
    to {
      transform: translate3d(74vw, -131vh, 0);
    }
  }
  @keyframes move-frames-74 {
    from {
      transform: translate3d(22vw, 108vh, 0);
    }
    to {
      transform: translate3d(74vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(74) .circle {
    -webkit-animation-delay: 1227ms;
            animation-delay: 1227ms;
  }
  .circle-container:nth-child(75) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-75;
            animation-name: move-frames-75;
    -webkit-animation-duration: 29677ms;
            animation-duration: 29677ms;
    -webkit-animation-delay: 11357ms;
            animation-delay: 11357ms;
  }
  @-webkit-keyframes move-frames-75 {
    from {
      transform: translate3d(85vw, 105vh, 0);
    }
    to {
      transform: translate3d(9vw, -121vh, 0);
    }
  }
  @keyframes move-frames-75 {
    from {
      transform: translate3d(85vw, 105vh, 0);
    }
    to {
      transform: translate3d(9vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(75) .circle {
    -webkit-animation-delay: 3855ms;
            animation-delay: 3855ms;
  }
  .circle-container:nth-child(76) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-76;
            animation-name: move-frames-76;
    -webkit-animation-duration: 28098ms;
            animation-duration: 28098ms;
    -webkit-animation-delay: 21078ms;
            animation-delay: 21078ms;
  }
  @-webkit-keyframes move-frames-76 {
    from {
      transform: translate3d(64vw, 106vh, 0);
    }
    to {
      transform: translate3d(37vw, -110vh, 0);
    }
  }
  @keyframes move-frames-76 {
    from {
      transform: translate3d(64vw, 106vh, 0);
    }
    to {
      transform: translate3d(37vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(76) .circle {
    -webkit-animation-delay: 1625ms;
            animation-delay: 1625ms;
  }
  .circle-container:nth-child(77) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-77;
            animation-name: move-frames-77;
    -webkit-animation-duration: 31160ms;
            animation-duration: 31160ms;
    -webkit-animation-delay: 15068ms;
            animation-delay: 15068ms;
  }
  @-webkit-keyframes move-frames-77 {
    from {
      transform: translate3d(5vw, 102vh, 0);
    }
    to {
      transform: translate3d(75vw, -132vh, 0);
    }
  }
  @keyframes move-frames-77 {
    from {
      transform: translate3d(5vw, 102vh, 0);
    }
    to {
      transform: translate3d(75vw, -132vh, 0);
    }
  }
  .circle-container:nth-child(77) .circle {
    -webkit-animation-delay: 1115ms;
            animation-delay: 1115ms;
  }
  .circle-container:nth-child(78) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-78;
            animation-name: move-frames-78;
    -webkit-animation-duration: 33847ms;
            animation-duration: 33847ms;
    -webkit-animation-delay: 27847ms;
            animation-delay: 27847ms;
  }
  @-webkit-keyframes move-frames-78 {
    from {
      transform: translate3d(38vw, 104vh, 0);
    }
    to {
      transform: translate3d(94vw, -109vh, 0);
    }
  }
  @keyframes move-frames-78 {
    from {
      transform: translate3d(38vw, 104vh, 0);
    }
    to {
      transform: translate3d(94vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(78) .circle {
    -webkit-animation-delay: 1004ms;
            animation-delay: 1004ms;
  }
  .circle-container:nth-child(79) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-79;
            animation-name: move-frames-79;
    -webkit-animation-duration: 34421ms;
            animation-duration: 34421ms;
    -webkit-animation-delay: 1451ms;
            animation-delay: 1451ms;
  }
  @-webkit-keyframes move-frames-79 {
    from {
      transform: translate3d(67vw, 110vh, 0);
    }
    to {
      transform: translate3d(54vw, -136vh, 0);
    }
  }
  @keyframes move-frames-79 {
    from {
      transform: translate3d(67vw, 110vh, 0);
    }
    to {
      transform: translate3d(54vw, -136vh, 0);
    }
  }
  .circle-container:nth-child(79) .circle {
    -webkit-animation-delay: 325ms;
            animation-delay: 325ms;
  }
  .circle-container:nth-child(80) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-80;
            animation-name: move-frames-80;
    -webkit-animation-duration: 29660ms;
            animation-duration: 29660ms;
    -webkit-animation-delay: 18242ms;
            animation-delay: 18242ms;
  }
  @-webkit-keyframes move-frames-80 {
    from {
      transform: translate3d(71vw, 103vh, 0);
    }
    to {
      transform: translate3d(84vw, -107vh, 0);
    }
  }
  @keyframes move-frames-80 {
    from {
      transform: translate3d(71vw, 103vh, 0);
    }
    to {
      transform: translate3d(84vw, -107vh, 0);
    }
  }
  .circle-container:nth-child(80) .circle {
    -webkit-animation-delay: 3062ms;
            animation-delay: 3062ms;
  }
  .circle-container:nth-child(81) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-81;
            animation-name: move-frames-81;
    -webkit-animation-duration: 36046ms;
            animation-duration: 36046ms;
    -webkit-animation-delay: 31912ms;
            animation-delay: 31912ms;
  }
  @-webkit-keyframes move-frames-81 {
    from {
      transform: translate3d(36vw, 105vh, 0);
    }
    to {
      transform: translate3d(11vw, -121vh, 0);
    }
  }
  @keyframes move-frames-81 {
    from {
      transform: translate3d(36vw, 105vh, 0);
    }
    to {
      transform: translate3d(11vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(81) .circle {
    -webkit-animation-delay: 3696ms;
            animation-delay: 3696ms;
  }
  .circle-container:nth-child(82) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-82;
            animation-name: move-frames-82;
    -webkit-animation-duration: 29551ms;
            animation-duration: 29551ms;
    -webkit-animation-delay: 6ms;
            animation-delay: 6ms;
  }
  @-webkit-keyframes move-frames-82 {
    from {
      transform: translate3d(67vw, 104vh, 0);
    }
    to {
      transform: translate3d(19vw, -121vh, 0);
    }
  }
  @keyframes move-frames-82 {
    from {
      transform: translate3d(67vw, 104vh, 0);
    }
    to {
      transform: translate3d(19vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(82) .circle {
    -webkit-animation-delay: 2794ms;
            animation-delay: 2794ms;
  }
  .circle-container:nth-child(83) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-83;
            animation-name: move-frames-83;
    -webkit-animation-duration: 32616ms;
            animation-duration: 32616ms;
    -webkit-animation-delay: 23064ms;
            animation-delay: 23064ms;
  }
  @-webkit-keyframes move-frames-83 {
    from {
      transform: translate3d(88vw, 103vh, 0);
    }
    to {
      transform: translate3d(68vw, -114vh, 0);
    }
  }
  @keyframes move-frames-83 {
    from {
      transform: translate3d(88vw, 103vh, 0);
    }
    to {
      transform: translate3d(68vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(83) .circle {
    -webkit-animation-delay: 1235ms;
            animation-delay: 1235ms;
  }
  .circle-container:nth-child(84) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-84;
            animation-name: move-frames-84;
    -webkit-animation-duration: 30728ms;
            animation-duration: 30728ms;
    -webkit-animation-delay: 19449ms;
            animation-delay: 19449ms;
  }
  @-webkit-keyframes move-frames-84 {
    from {
      transform: translate3d(32vw, 109vh, 0);
    }
    to {
      transform: translate3d(32vw, -129vh, 0);
    }
  }
  @keyframes move-frames-84 {
    from {
      transform: translate3d(32vw, 109vh, 0);
    }
    to {
      transform: translate3d(32vw, -129vh, 0);
    }
  }
  .circle-container:nth-child(84) .circle {
    -webkit-animation-delay: 890ms;
            animation-delay: 890ms;
  }
  .circle-container:nth-child(85) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-85;
            animation-name: move-frames-85;
    -webkit-animation-duration: 33175ms;
            animation-duration: 33175ms;
    -webkit-animation-delay: 1527ms;
            animation-delay: 1527ms;
  }
  @-webkit-keyframes move-frames-85 {
    from {
      transform: translate3d(62vw, 102vh, 0);
    }
    to {
      transform: translate3d(62vw, -129vh, 0);
    }
  }
  @keyframes move-frames-85 {
    from {
      transform: translate3d(62vw, 102vh, 0);
    }
    to {
      transform: translate3d(62vw, -129vh, 0);
    }
  }
  .circle-container:nth-child(85) .circle {
    -webkit-animation-delay: 818ms;
            animation-delay: 818ms;
  }
  .circle-container:nth-child(86) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-86;
            animation-name: move-frames-86;
    -webkit-animation-duration: 34137ms;
            animation-duration: 34137ms;
    -webkit-animation-delay: 17877ms;
            animation-delay: 17877ms;
  }
  @-webkit-keyframes move-frames-86 {
    from {
      transform: translate3d(18vw, 105vh, 0);
    }
    to {
      transform: translate3d(15vw, -113vh, 0);
    }
  }
  @keyframes move-frames-86 {
    from {
      transform: translate3d(18vw, 105vh, 0);
    }
    to {
      transform: translate3d(15vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(86) .circle {
    -webkit-animation-delay: 3389ms;
            animation-delay: 3389ms;
  }
  .circle-container:nth-child(87) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-87;
            animation-name: move-frames-87;
    -webkit-animation-duration: 33746ms;
            animation-duration: 33746ms;
    -webkit-animation-delay: 29842ms;
            animation-delay: 29842ms;
  }
  @-webkit-keyframes move-frames-87 {
    from {
      transform: translate3d(84vw, 101vh, 0);
    }
    to {
      transform: translate3d(33vw, -103vh, 0);
    }
  }
  @keyframes move-frames-87 {
    from {
      transform: translate3d(84vw, 101vh, 0);
    }
    to {
      transform: translate3d(33vw, -103vh, 0);
    }
  }
  .circle-container:nth-child(87) .circle {
    -webkit-animation-delay: 255ms;
            animation-delay: 255ms;
  }
  .circle-container:nth-child(88) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-88;
            animation-name: move-frames-88;
    -webkit-animation-duration: 28278ms;
            animation-duration: 28278ms;
    -webkit-animation-delay: 30241ms;
            animation-delay: 30241ms;
  }
  @-webkit-keyframes move-frames-88 {
    from {
      transform: translate3d(63vw, 103vh, 0);
    }
    to {
      transform: translate3d(7vw, -115vh, 0);
    }
  }
  @keyframes move-frames-88 {
    from {
      transform: translate3d(63vw, 103vh, 0);
    }
    to {
      transform: translate3d(7vw, -115vh, 0);
    }
  }
  .circle-container:nth-child(88) .circle {
    -webkit-animation-delay: 237ms;
            animation-delay: 237ms;
  }
  .circle-container:nth-child(89) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-89;
            animation-name: move-frames-89;
    -webkit-animation-duration: 33453ms;
            animation-duration: 33453ms;
    -webkit-animation-delay: 11451ms;
            animation-delay: 11451ms;
  }
  @-webkit-keyframes move-frames-89 {
    from {
      transform: translate3d(79vw, 105vh, 0);
    }
    to {
      transform: translate3d(89vw, -110vh, 0);
    }
  }
  @keyframes move-frames-89 {
    from {
      transform: translate3d(79vw, 105vh, 0);
    }
    to {
      transform: translate3d(89vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(89) .circle {
    -webkit-animation-delay: 3912ms;
            animation-delay: 3912ms;
  }
  .circle-container:nth-child(90) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-90;
            animation-name: move-frames-90;
    -webkit-animation-duration: 35501ms;
            animation-duration: 35501ms;
    -webkit-animation-delay: 35028ms;
            animation-delay: 35028ms;
  }
  @-webkit-keyframes move-frames-90 {
    from {
      transform: translate3d(100vw, 110vh, 0);
    }
    to {
      transform: translate3d(76vw, -111vh, 0);
    }
  }
  @keyframes move-frames-90 {
    from {
      transform: translate3d(100vw, 110vh, 0);
    }
    to {
      transform: translate3d(76vw, -111vh, 0);
    }
  }
  .circle-container:nth-child(90) .circle {
    -webkit-animation-delay: 77ms;
            animation-delay: 77ms;
  }
  .circle-container:nth-child(91) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-91;
            animation-name: move-frames-91;
    -webkit-animation-duration: 32610ms;
            animation-duration: 32610ms;
    -webkit-animation-delay: 8431ms;
            animation-delay: 8431ms;
  }
  @-webkit-keyframes move-frames-91 {
    from {
      transform: translate3d(91vw, 105vh, 0);
    }
    to {
      transform: translate3d(12vw, -111vh, 0);
    }
  }
  @keyframes move-frames-91 {
    from {
      transform: translate3d(91vw, 105vh, 0);
    }
    to {
      transform: translate3d(12vw, -111vh, 0);
    }
  }
  .circle-container:nth-child(91) .circle {
    -webkit-animation-delay: 1953ms;
            animation-delay: 1953ms;
  }
  .circle-container:nth-child(92) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-92;
            animation-name: move-frames-92;
    -webkit-animation-duration: 29809ms;
            animation-duration: 29809ms;
    -webkit-animation-delay: 31478ms;
            animation-delay: 31478ms;
  }
  @-webkit-keyframes move-frames-92 {
    from {
      transform: translate3d(77vw, 106vh, 0);
    }
    to {
      transform: translate3d(86vw, -135vh, 0);
    }
  }
  @keyframes move-frames-92 {
    from {
      transform: translate3d(77vw, 106vh, 0);
    }
    to {
      transform: translate3d(86vw, -135vh, 0);
    }
  }
  .circle-container:nth-child(92) .circle {
    -webkit-animation-delay: 2914ms;
            animation-delay: 2914ms;
  }
  .circle-container:nth-child(93) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-93;
            animation-name: move-frames-93;
    -webkit-animation-duration: 36259ms;
            animation-duration: 36259ms;
    -webkit-animation-delay: 29294ms;
            animation-delay: 29294ms;
  }
  @-webkit-keyframes move-frames-93 {
    from {
      transform: translate3d(94vw, 106vh, 0);
    }
    to {
      transform: translate3d(4vw, -109vh, 0);
    }
  }
  @keyframes move-frames-93 {
    from {
      transform: translate3d(94vw, 106vh, 0);
    }
    to {
      transform: translate3d(4vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(93) .circle {
    -webkit-animation-delay: 209ms;
            animation-delay: 209ms;
  }
  .circle-container:nth-child(94) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-94;
            animation-name: move-frames-94;
    -webkit-animation-duration: 33514ms;
            animation-duration: 33514ms;
    -webkit-animation-delay: 6544ms;
            animation-delay: 6544ms;
  }
  @-webkit-keyframes move-frames-94 {
    from {
      transform: translate3d(85vw, 110vh, 0);
    }
    to {
      transform: translate3d(2vw, -117vh, 0);
    }
  }
  @keyframes move-frames-94 {
    from {
      transform: translate3d(85vw, 110vh, 0);
    }
    to {
      transform: translate3d(2vw, -117vh, 0);
    }
  }
  .circle-container:nth-child(94) .circle {
    -webkit-animation-delay: 1344ms;
            animation-delay: 1344ms;
  }
  .circle-container:nth-child(95) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-95;
            animation-name: move-frames-95;
    -webkit-animation-duration: 35109ms;
            animation-duration: 35109ms;
    -webkit-animation-delay: 25674ms;
            animation-delay: 25674ms;
  }
  @-webkit-keyframes move-frames-95 {
    from {
      transform: translate3d(79vw, 107vh, 0);
    }
    to {
      transform: translate3d(85vw, -127vh, 0);
    }
  }
  @keyframes move-frames-95 {
    from {
      transform: translate3d(79vw, 107vh, 0);
    }
    to {
      transform: translate3d(85vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(95) .circle {
    -webkit-animation-delay: 760ms;
            animation-delay: 760ms;
  }
  .circle-container:nth-child(96) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-96;
            animation-name: move-frames-96;
    -webkit-animation-duration: 31415ms;
            animation-duration: 31415ms;
    -webkit-animation-delay: 7317ms;
            animation-delay: 7317ms;
  }
  @-webkit-keyframes move-frames-96 {
    from {
      transform: translate3d(13vw, 105vh, 0);
    }
    to {
      transform: translate3d(98vw, -128vh, 0);
    }
  }
  @keyframes move-frames-96 {
    from {
      transform: translate3d(13vw, 105vh, 0);
    }
    to {
      transform: translate3d(98vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(96) .circle {
    -webkit-animation-delay: 2832ms;
            animation-delay: 2832ms;
  }
  .circle-container:nth-child(97) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-97;
            animation-name: move-frames-97;
    -webkit-animation-duration: 32484ms;
            animation-duration: 32484ms;
    -webkit-animation-delay: 18353ms;
            animation-delay: 18353ms;
  }
  @-webkit-keyframes move-frames-97 {
    from {
      transform: translate3d(8vw, 106vh, 0);
    }
    to {
      transform: translate3d(100vw, -119vh, 0);
    }
  }
  @keyframes move-frames-97 {
    from {
      transform: translate3d(8vw, 106vh, 0);
    }
    to {
      transform: translate3d(100vw, -119vh, 0);
    }
  }
  .circle-container:nth-child(97) .circle {
    -webkit-animation-delay: 2224ms;
            animation-delay: 2224ms;
  }
  .circle-container:nth-child(98) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-98;
            animation-name: move-frames-98;
    -webkit-animation-duration: 31438ms;
            animation-duration: 31438ms;
    -webkit-animation-delay: 7508ms;
            animation-delay: 7508ms;
  }
  @-webkit-keyframes move-frames-98 {
    from {
      transform: translate3d(98vw, 107vh, 0);
    }
    to {
      transform: translate3d(39vw, -122vh, 0);
    }
  }
  @keyframes move-frames-98 {
    from {
      transform: translate3d(98vw, 107vh, 0);
    }
    to {
      transform: translate3d(39vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(98) .circle {
    -webkit-animation-delay: 2722ms;
            animation-delay: 2722ms;
  }
  .circle-container:nth-child(99) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-99;
            animation-name: move-frames-99;
    -webkit-animation-duration: 32301ms;
            animation-duration: 32301ms;
    -webkit-animation-delay: 9375ms;
            animation-delay: 9375ms;
  }
  @-webkit-keyframes move-frames-99 {
    from {
      transform: translate3d(100vw, 104vh, 0);
    }
    to {
      transform: translate3d(15vw, -113vh, 0);
    }
  }
  @keyframes move-frames-99 {
    from {
      transform: translate3d(100vw, 104vh, 0);
    }
    to {
      transform: translate3d(15vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(99) .circle {
    -webkit-animation-delay: 3012ms;
            animation-delay: 3012ms;
  }
  .circle-container:nth-child(100) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-100;
            animation-name: move-frames-100;
    -webkit-animation-duration: 29199ms;
            animation-duration: 29199ms;
    -webkit-animation-delay: 17125ms;
            animation-delay: 17125ms;
  }
  @-webkit-keyframes move-frames-100 {
    from {
      transform: translate3d(12vw, 104vh, 0);
    }
    to {
      transform: translate3d(24vw, -133vh, 0);
    }
  }
  @keyframes move-frames-100 {
    from {
      transform: translate3d(12vw, 104vh, 0);
    }
    to {
      transform: translate3d(24vw, -133vh, 0);
    }
  }
  .circle-container:nth-child(100) .circle {
    -webkit-animation-delay: 753ms;
            animation-delay: 753ms;
  }
  .circle-container:nth-child(101) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-101;
            animation-name: move-frames-101;
    -webkit-animation-duration: 28666ms;
            animation-duration: 28666ms;
    -webkit-animation-delay: 18567ms;
            animation-delay: 18567ms;
  }
  @-webkit-keyframes move-frames-101 {
    from {
      transform: translate3d(74vw, 109vh, 0);
    }
    to {
      transform: translate3d(31vw, -114vh, 0);
    }
  }
  @keyframes move-frames-101 {
    from {
      transform: translate3d(74vw, 109vh, 0);
    }
    to {
      transform: translate3d(31vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(101) .circle {
    -webkit-animation-delay: 751ms;
            animation-delay: 751ms;
  }
  .circle-container:nth-child(102) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-102;
            animation-name: move-frames-102;
    -webkit-animation-duration: 34910ms;
            animation-duration: 34910ms;
    -webkit-animation-delay: 1211ms;
            animation-delay: 1211ms;
  }
  @-webkit-keyframes move-frames-102 {
    from {
      transform: translate3d(98vw, 104vh, 0);
    }
    to {
      transform: translate3d(40vw, -112vh, 0);
    }
  }
  @keyframes move-frames-102 {
    from {
      transform: translate3d(98vw, 104vh, 0);
    }
    to {
      transform: translate3d(40vw, -112vh, 0);
    }
  }
  .circle-container:nth-child(102) .circle {
    -webkit-animation-delay: 3461ms;
            animation-delay: 3461ms;
  }
  .circle-container:nth-child(103) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-103;
            animation-name: move-frames-103;
    -webkit-animation-duration: 29034ms;
            animation-duration: 29034ms;
    -webkit-animation-delay: 932ms;
            animation-delay: 932ms;
  }
  @-webkit-keyframes move-frames-103 {
    from {
      transform: translate3d(79vw, 110vh, 0);
    }
    to {
      transform: translate3d(65vw, -123vh, 0);
    }
  }
  @keyframes move-frames-103 {
    from {
      transform: translate3d(79vw, 110vh, 0);
    }
    to {
      transform: translate3d(65vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(103) .circle {
    -webkit-animation-delay: 1581ms;
            animation-delay: 1581ms;
  }
  .circle-container:nth-child(104) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-104;
            animation-name: move-frames-104;
    -webkit-animation-duration: 29624ms;
            animation-duration: 29624ms;
    -webkit-animation-delay: 15199ms;
            animation-delay: 15199ms;
  }
  @-webkit-keyframes move-frames-104 {
    from {
      transform: translate3d(43vw, 108vh, 0);
    }
    to {
      transform: translate3d(63vw, -137vh, 0);
    }
  }
  @keyframes move-frames-104 {
    from {
      transform: translate3d(43vw, 108vh, 0);
    }
    to {
      transform: translate3d(63vw, -137vh, 0);
    }
  }
  .circle-container:nth-child(104) .circle {
    -webkit-animation-delay: 3102ms;
            animation-delay: 3102ms;
  }
  .circle-container:nth-child(105) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-105;
            animation-name: move-frames-105;
    -webkit-animation-duration: 32600ms;
            animation-duration: 32600ms;
    -webkit-animation-delay: 919ms;
            animation-delay: 919ms;
  }
  @-webkit-keyframes move-frames-105 {
    from {
      transform: translate3d(89vw, 110vh, 0);
    }
    to {
      transform: translate3d(59vw, -137vh, 0);
    }
  }
  @keyframes move-frames-105 {
    from {
      transform: translate3d(89vw, 110vh, 0);
    }
    to {
      transform: translate3d(59vw, -137vh, 0);
    }
  }
  .circle-container:nth-child(105) .circle {
    -webkit-animation-delay: 53ms;
            animation-delay: 53ms;
  }
  .circle-container:nth-child(106) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-106;
            animation-name: move-frames-106;
    -webkit-animation-duration: 28574ms;
            animation-duration: 28574ms;
    -webkit-animation-delay: 23147ms;
            animation-delay: 23147ms;
  }
  @-webkit-keyframes move-frames-106 {
    from {
      transform: translate3d(60vw, 108vh, 0);
    }
    to {
      transform: translate3d(7vw, -134vh, 0);
    }
  }
  @keyframes move-frames-106 {
    from {
      transform: translate3d(60vw, 108vh, 0);
    }
    to {
      transform: translate3d(7vw, -134vh, 0);
    }
  }
  .circle-container:nth-child(106) .circle {
    -webkit-animation-delay: 3742ms;
            animation-delay: 3742ms;
  }
  .circle-container:nth-child(107) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-107;
            animation-name: move-frames-107;
    -webkit-animation-duration: 36211ms;
            animation-duration: 36211ms;
    -webkit-animation-delay: 32669ms;
            animation-delay: 32669ms;
  }
  @-webkit-keyframes move-frames-107 {
    from {
      transform: translate3d(63vw, 105vh, 0);
    }
    to {
      transform: translate3d(12vw, -110vh, 0);
    }
  }
  @keyframes move-frames-107 {
    from {
      transform: translate3d(63vw, 105vh, 0);
    }
    to {
      transform: translate3d(12vw, -110vh, 0);
    }
  }
  .circle-container:nth-child(107) .circle {
    -webkit-animation-delay: 2801ms;
            animation-delay: 2801ms;
  }
  .circle-container:nth-child(108) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-108;
            animation-name: move-frames-108;
    -webkit-animation-duration: 36502ms;
            animation-duration: 36502ms;
    -webkit-animation-delay: 28785ms;
            animation-delay: 28785ms;
  }
  @-webkit-keyframes move-frames-108 {
    from {
      transform: translate3d(83vw, 102vh, 0);
    }
    to {
      transform: translate3d(9vw, -120vh, 0);
    }
  }
  @keyframes move-frames-108 {
    from {
      transform: translate3d(83vw, 102vh, 0);
    }
    to {
      transform: translate3d(9vw, -120vh, 0);
    }
  }
  .circle-container:nth-child(108) .circle {
    -webkit-animation-delay: 1877ms;
            animation-delay: 1877ms;
  }
  .circle-container:nth-child(109) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-109;
            animation-name: move-frames-109;
    -webkit-animation-duration: 29662ms;
            animation-duration: 29662ms;
    -webkit-animation-delay: 31604ms;
            animation-delay: 31604ms;
  }
  @-webkit-keyframes move-frames-109 {
    from {
      transform: translate3d(40vw, 108vh, 0);
    }
    to {
      transform: translate3d(76vw, -121vh, 0);
    }
  }
  @keyframes move-frames-109 {
    from {
      transform: translate3d(40vw, 108vh, 0);
    }
    to {
      transform: translate3d(76vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(109) .circle {
    -webkit-animation-delay: 2095ms;
            animation-delay: 2095ms;
  }
  .circle-container:nth-child(110) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-110;
            animation-name: move-frames-110;
    -webkit-animation-duration: 32320ms;
            animation-duration: 32320ms;
    -webkit-animation-delay: 30387ms;
            animation-delay: 30387ms;
  }
  @-webkit-keyframes move-frames-110 {
    from {
      transform: translate3d(22vw, 103vh, 0);
    }
    to {
      transform: translate3d(51vw, -109vh, 0);
    }
  }
  @keyframes move-frames-110 {
    from {
      transform: translate3d(22vw, 103vh, 0);
    }
    to {
      transform: translate3d(51vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(110) .circle {
    -webkit-animation-delay: 753ms;
            animation-delay: 753ms;
  }
  .circle-container:nth-child(111) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-111;
            animation-name: move-frames-111;
    -webkit-animation-duration: 31868ms;
            animation-duration: 31868ms;
    -webkit-animation-delay: 15047ms;
            animation-delay: 15047ms;
  }
  @-webkit-keyframes move-frames-111 {
    from {
      transform: translate3d(81vw, 104vh, 0);
    }
    to {
      transform: translate3d(21vw, -132vh, 0);
    }
  }
  @keyframes move-frames-111 {
    from {
      transform: translate3d(81vw, 104vh, 0);
    }
    to {
      transform: translate3d(21vw, -132vh, 0);
    }
  }
  .circle-container:nth-child(111) .circle {
    -webkit-animation-delay: 3254ms;
            animation-delay: 3254ms;
  }
  .circle-container:nth-child(112) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-112;
            animation-name: move-frames-112;
    -webkit-animation-duration: 34163ms;
            animation-duration: 34163ms;
    -webkit-animation-delay: 28610ms;
            animation-delay: 28610ms;
  }
  @-webkit-keyframes move-frames-112 {
    from {
      transform: translate3d(33vw, 105vh, 0);
    }
    to {
      transform: translate3d(66vw, -124vh, 0);
    }
  }
  @keyframes move-frames-112 {
    from {
      transform: translate3d(33vw, 105vh, 0);
    }
    to {
      transform: translate3d(66vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(112) .circle {
    -webkit-animation-delay: 3590ms;
            animation-delay: 3590ms;
  }
  .circle-container:nth-child(113) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-113;
            animation-name: move-frames-113;
    -webkit-animation-duration: 30406ms;
            animation-duration: 30406ms;
    -webkit-animation-delay: 10143ms;
            animation-delay: 10143ms;
  }
  @-webkit-keyframes move-frames-113 {
    from {
      transform: translate3d(69vw, 102vh, 0);
    }
    to {
      transform: translate3d(93vw, -126vh, 0);
    }
  }
  @keyframes move-frames-113 {
    from {
      transform: translate3d(69vw, 102vh, 0);
    }
    to {
      transform: translate3d(93vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(113) .circle {
    -webkit-animation-delay: 2366ms;
            animation-delay: 2366ms;
  }
  .circle-container:nth-child(114) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-114;
            animation-name: move-frames-114;
    -webkit-animation-duration: 35756ms;
            animation-duration: 35756ms;
    -webkit-animation-delay: 24700ms;
            animation-delay: 24700ms;
  }
  @-webkit-keyframes move-frames-114 {
    from {
      transform: translate3d(90vw, 101vh, 0);
    }
    to {
      transform: translate3d(25vw, -102vh, 0);
    }
  }
  @keyframes move-frames-114 {
    from {
      transform: translate3d(90vw, 101vh, 0);
    }
    to {
      transform: translate3d(25vw, -102vh, 0);
    }
  }
  .circle-container:nth-child(114) .circle {
    -webkit-animation-delay: 2914ms;
            animation-delay: 2914ms;
  }
  .circle-container:nth-child(115) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-115;
            animation-name: move-frames-115;
    -webkit-animation-duration: 35542ms;
            animation-duration: 35542ms;
    -webkit-animation-delay: 24790ms;
            animation-delay: 24790ms;
  }
  @-webkit-keyframes move-frames-115 {
    from {
      transform: translate3d(8vw, 103vh, 0);
    }
    to {
      transform: translate3d(33vw, -120vh, 0);
    }
  }
  @keyframes move-frames-115 {
    from {
      transform: translate3d(8vw, 103vh, 0);
    }
    to {
      transform: translate3d(33vw, -120vh, 0);
    }
  }
  .circle-container:nth-child(115) .circle {
    -webkit-animation-delay: 1964ms;
            animation-delay: 1964ms;
  }
  .circle-container:nth-child(116) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-116;
            animation-name: move-frames-116;
    -webkit-animation-duration: 33667ms;
            animation-duration: 33667ms;
    -webkit-animation-delay: 18754ms;
            animation-delay: 18754ms;
  }
  @-webkit-keyframes move-frames-116 {
    from {
      transform: translate3d(18vw, 108vh, 0);
    }
    to {
      transform: translate3d(43vw, -138vh, 0);
    }
  }
  @keyframes move-frames-116 {
    from {
      transform: translate3d(18vw, 108vh, 0);
    }
    to {
      transform: translate3d(43vw, -138vh, 0);
    }
  }
  .circle-container:nth-child(116) .circle {
    -webkit-animation-delay: 1595ms;
            animation-delay: 1595ms;
  }
  .circle-container:nth-child(117) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-117;
            animation-name: move-frames-117;
    -webkit-animation-duration: 34020ms;
            animation-duration: 34020ms;
    -webkit-animation-delay: 14846ms;
            animation-delay: 14846ms;
  }
  @-webkit-keyframes move-frames-117 {
    from {
      transform: translate3d(17vw, 103vh, 0);
    }
    to {
      transform: translate3d(75vw, -113vh, 0);
    }
  }
  @keyframes move-frames-117 {
    from {
      transform: translate3d(17vw, 103vh, 0);
    }
    to {
      transform: translate3d(75vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(117) .circle {
    -webkit-animation-delay: 2147ms;
            animation-delay: 2147ms;
  }
  .circle-container:nth-child(118) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-118;
            animation-name: move-frames-118;
    -webkit-animation-duration: 33447ms;
            animation-duration: 33447ms;
    -webkit-animation-delay: 30984ms;
            animation-delay: 30984ms;
  }
  @-webkit-keyframes move-frames-118 {
    from {
      transform: translate3d(5vw, 107vh, 0);
    }
    to {
      transform: translate3d(45vw, -126vh, 0);
    }
  }
  @keyframes move-frames-118 {
    from {
      transform: translate3d(5vw, 107vh, 0);
    }
    to {
      transform: translate3d(45vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(118) .circle {
    -webkit-animation-delay: 2891ms;
            animation-delay: 2891ms;
  }
  .circle-container:nth-child(119) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-119;
            animation-name: move-frames-119;
    -webkit-animation-duration: 34970ms;
            animation-duration: 34970ms;
    -webkit-animation-delay: 20106ms;
            animation-delay: 20106ms;
  }
  @-webkit-keyframes move-frames-119 {
    from {
      transform: translate3d(25vw, 107vh, 0);
    }
    to {
      transform: translate3d(77vw, -114vh, 0);
    }
  }
  @keyframes move-frames-119 {
    from {
      transform: translate3d(25vw, 107vh, 0);
    }
    to {
      transform: translate3d(77vw, -114vh, 0);
    }
  }
  .circle-container:nth-child(119) .circle {
    -webkit-animation-delay: 96ms;
            animation-delay: 96ms;
  }
  .circle-container:nth-child(120) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-120;
            animation-name: move-frames-120;
    -webkit-animation-duration: 32744ms;
            animation-duration: 32744ms;
    -webkit-animation-delay: 28701ms;
            animation-delay: 28701ms;
  }
  @-webkit-keyframes move-frames-120 {
    from {
      transform: translate3d(30vw, 110vh, 0);
    }
    to {
      transform: translate3d(87vw, -140vh, 0);
    }
  }
  @keyframes move-frames-120 {
    from {
      transform: translate3d(30vw, 110vh, 0);
    }
    to {
      transform: translate3d(87vw, -140vh, 0);
    }
  }
  .circle-container:nth-child(120) .circle {
    -webkit-animation-delay: 674ms;
            animation-delay: 674ms;
  }
  .circle-container:nth-child(121) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-121;
            animation-name: move-frames-121;
    -webkit-animation-duration: 34539ms;
            animation-duration: 34539ms;
    -webkit-animation-delay: 15196ms;
            animation-delay: 15196ms;
  }
  @-webkit-keyframes move-frames-121 {
    from {
      transform: translate3d(88vw, 107vh, 0);
    }
    to {
      transform: translate3d(7vw, -131vh, 0);
    }
  }
  @keyframes move-frames-121 {
    from {
      transform: translate3d(88vw, 107vh, 0);
    }
    to {
      transform: translate3d(7vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(121) .circle {
    -webkit-animation-delay: 2303ms;
            animation-delay: 2303ms;
  }
  .circle-container:nth-child(122) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-122;
            animation-name: move-frames-122;
    -webkit-animation-duration: 29328ms;
            animation-duration: 29328ms;
    -webkit-animation-delay: 23523ms;
            animation-delay: 23523ms;
  }
  @-webkit-keyframes move-frames-122 {
    from {
      transform: translate3d(98vw, 101vh, 0);
    }
    to {
      transform: translate3d(89vw, -107vh, 0);
    }
  }
  @keyframes move-frames-122 {
    from {
      transform: translate3d(98vw, 101vh, 0);
    }
    to {
      transform: translate3d(89vw, -107vh, 0);
    }
  }
  .circle-container:nth-child(122) .circle {
    -webkit-animation-delay: 1218ms;
            animation-delay: 1218ms;
  }
  .circle-container:nth-child(123) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-123;
            animation-name: move-frames-123;
    -webkit-animation-duration: 34884ms;
            animation-duration: 34884ms;
    -webkit-animation-delay: 14734ms;
            animation-delay: 14734ms;
  }
  @-webkit-keyframes move-frames-123 {
    from {
      transform: translate3d(11vw, 106vh, 0);
    }
    to {
      transform: translate3d(18vw, -131vh, 0);
    }
  }
  @keyframes move-frames-123 {
    from {
      transform: translate3d(11vw, 106vh, 0);
    }
    to {
      transform: translate3d(18vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(123) .circle {
    -webkit-animation-delay: 2907ms;
            animation-delay: 2907ms;
  }
  .circle-container:nth-child(124) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-124;
            animation-name: move-frames-124;
    -webkit-animation-duration: 29808ms;
            animation-duration: 29808ms;
    -webkit-animation-delay: 7071ms;
            animation-delay: 7071ms;
  }
  @-webkit-keyframes move-frames-124 {
    from {
      transform: translate3d(86vw, 110vh, 0);
    }
    to {
      transform: translate3d(28vw, -125vh, 0);
    }
  }
  @keyframes move-frames-124 {
    from {
      transform: translate3d(86vw, 110vh, 0);
    }
    to {
      transform: translate3d(28vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(124) .circle {
    -webkit-animation-delay: 1211ms;
            animation-delay: 1211ms;
  }
  .circle-container:nth-child(125) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-125;
            animation-name: move-frames-125;
    -webkit-animation-duration: 36792ms;
            animation-duration: 36792ms;
    -webkit-animation-delay: 10963ms;
            animation-delay: 10963ms;
  }
  @-webkit-keyframes move-frames-125 {
    from {
      transform: translate3d(58vw, 101vh, 0);
    }
    to {
      transform: translate3d(84vw, -102vh, 0);
    }
  }
  @keyframes move-frames-125 {
    from {
      transform: translate3d(58vw, 101vh, 0);
    }
    to {
      transform: translate3d(84vw, -102vh, 0);
    }
  }
  .circle-container:nth-child(125) .circle {
    -webkit-animation-delay: 422ms;
            animation-delay: 422ms;
  }
  .circle-container:nth-child(126) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-126;
            animation-name: move-frames-126;
    -webkit-animation-duration: 33428ms;
            animation-duration: 33428ms;
    -webkit-animation-delay: 7038ms;
            animation-delay: 7038ms;
  }
  @-webkit-keyframes move-frames-126 {
    from {
      transform: translate3d(31vw, 105vh, 0);
    }
    to {
      transform: translate3d(99vw, -120vh, 0);
    }
  }
  @keyframes move-frames-126 {
    from {
      transform: translate3d(31vw, 105vh, 0);
    }
    to {
      transform: translate3d(99vw, -120vh, 0);
    }
  }
  .circle-container:nth-child(126) .circle {
    -webkit-animation-delay: 3560ms;
            animation-delay: 3560ms;
  }
  .circle-container:nth-child(127) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-127;
            animation-name: move-frames-127;
    -webkit-animation-duration: 33218ms;
            animation-duration: 33218ms;
    -webkit-animation-delay: 4466ms;
            animation-delay: 4466ms;
  }
  @-webkit-keyframes move-frames-127 {
    from {
      transform: translate3d(42vw, 106vh, 0);
    }
    to {
      transform: translate3d(6vw, -113vh, 0);
    }
  }
  @keyframes move-frames-127 {
    from {
      transform: translate3d(42vw, 106vh, 0);
    }
    to {
      transform: translate3d(6vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(127) .circle {
    -webkit-animation-delay: 2037ms;
            animation-delay: 2037ms;
  }
  .circle-container:nth-child(128) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-128;
            animation-name: move-frames-128;
    -webkit-animation-duration: 29097ms;
            animation-duration: 29097ms;
    -webkit-animation-delay: 18115ms;
            animation-delay: 18115ms;
  }
  @-webkit-keyframes move-frames-128 {
    from {
      transform: translate3d(79vw, 106vh, 0);
    }
    to {
      transform: translate3d(67vw, -132vh, 0);
    }
  }
  @keyframes move-frames-128 {
    from {
      transform: translate3d(79vw, 106vh, 0);
    }
    to {
      transform: translate3d(67vw, -132vh, 0);
    }
  }
  .circle-container:nth-child(128) .circle {
    -webkit-animation-delay: 1742ms;
            animation-delay: 1742ms;
  }
  .circle-container:nth-child(129) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-129;
            animation-name: move-frames-129;
    -webkit-animation-duration: 29096ms;
            animation-duration: 29096ms;
    -webkit-animation-delay: 21285ms;
            animation-delay: 21285ms;
  }
  @-webkit-keyframes move-frames-129 {
    from {
      transform: translate3d(76vw, 102vh, 0);
    }
    to {
      transform: translate3d(10vw, -122vh, 0);
    }
  }
  @keyframes move-frames-129 {
    from {
      transform: translate3d(76vw, 102vh, 0);
    }
    to {
      transform: translate3d(10vw, -122vh, 0);
    }
  }
  .circle-container:nth-child(129) .circle {
    -webkit-animation-delay: 721ms;
            animation-delay: 721ms;
  }
  .circle-container:nth-child(130) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-130;
            animation-name: move-frames-130;
    -webkit-animation-duration: 36510ms;
            animation-duration: 36510ms;
    -webkit-animation-delay: 29054ms;
            animation-delay: 29054ms;
  }
  @-webkit-keyframes move-frames-130 {
    from {
      transform: translate3d(81vw, 107vh, 0);
    }
    to {
      transform: translate3d(23vw, -111vh, 0);
    }
  }
  @keyframes move-frames-130 {
    from {
      transform: translate3d(81vw, 107vh, 0);
    }
    to {
      transform: translate3d(23vw, -111vh, 0);
    }
  }
  .circle-container:nth-child(130) .circle {
    -webkit-animation-delay: 688ms;
            animation-delay: 688ms;
  }
  .circle-container:nth-child(131) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-131;
            animation-name: move-frames-131;
    -webkit-animation-duration: 35501ms;
            animation-duration: 35501ms;
    -webkit-animation-delay: 22987ms;
            animation-delay: 22987ms;
  }
  @-webkit-keyframes move-frames-131 {
    from {
      transform: translate3d(27vw, 106vh, 0);
    }
    to {
      transform: translate3d(100vw, -124vh, 0);
    }
  }
  @keyframes move-frames-131 {
    from {
      transform: translate3d(27vw, 106vh, 0);
    }
    to {
      transform: translate3d(100vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(131) .circle {
    -webkit-animation-delay: 733ms;
            animation-delay: 733ms;
  }
  .circle-container:nth-child(132) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-132;
            animation-name: move-frames-132;
    -webkit-animation-duration: 31707ms;
            animation-duration: 31707ms;
    -webkit-animation-delay: 9240ms;
            animation-delay: 9240ms;
  }
  @-webkit-keyframes move-frames-132 {
    from {
      transform: translate3d(58vw, 104vh, 0);
    }
    to {
      transform: translate3d(30vw, -112vh, 0);
    }
  }
  @keyframes move-frames-132 {
    from {
      transform: translate3d(58vw, 104vh, 0);
    }
    to {
      transform: translate3d(30vw, -112vh, 0);
    }
  }
  .circle-container:nth-child(132) .circle {
    -webkit-animation-delay: 1533ms;
            animation-delay: 1533ms;
  }
  .circle-container:nth-child(133) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-133;
            animation-name: move-frames-133;
    -webkit-animation-duration: 30209ms;
            animation-duration: 30209ms;
    -webkit-animation-delay: 34680ms;
            animation-delay: 34680ms;
  }
  @-webkit-keyframes move-frames-133 {
    from {
      transform: translate3d(98vw, 102vh, 0);
    }
    to {
      transform: translate3d(53vw, -107vh, 0);
    }
  }
  @keyframes move-frames-133 {
    from {
      transform: translate3d(98vw, 102vh, 0);
    }
    to {
      transform: translate3d(53vw, -107vh, 0);
    }
  }
  .circle-container:nth-child(133) .circle {
    -webkit-animation-delay: 1668ms;
            animation-delay: 1668ms;
  }
  .circle-container:nth-child(134) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-134;
            animation-name: move-frames-134;
    -webkit-animation-duration: 32159ms;
            animation-duration: 32159ms;
    -webkit-animation-delay: 34972ms;
            animation-delay: 34972ms;
  }
  @-webkit-keyframes move-frames-134 {
    from {
      transform: translate3d(98vw, 106vh, 0);
    }
    to {
      transform: translate3d(95vw, -116vh, 0);
    }
  }
  @keyframes move-frames-134 {
    from {
      transform: translate3d(98vw, 106vh, 0);
    }
    to {
      transform: translate3d(95vw, -116vh, 0);
    }
  }
  .circle-container:nth-child(134) .circle {
    -webkit-animation-delay: 1554ms;
            animation-delay: 1554ms;
  }
  .circle-container:nth-child(135) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-135;
            animation-name: move-frames-135;
    -webkit-animation-duration: 31571ms;
            animation-duration: 31571ms;
    -webkit-animation-delay: 10922ms;
            animation-delay: 10922ms;
  }
  @-webkit-keyframes move-frames-135 {
    from {
      transform: translate3d(31vw, 108vh, 0);
    }
    to {
      transform: translate3d(33vw, -127vh, 0);
    }
  }
  @keyframes move-frames-135 {
    from {
      transform: translate3d(31vw, 108vh, 0);
    }
    to {
      transform: translate3d(33vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(135) .circle {
    -webkit-animation-delay: 3188ms;
            animation-delay: 3188ms;
  }
  .circle-container:nth-child(136) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-136;
            animation-name: move-frames-136;
    -webkit-animation-duration: 28388ms;
            animation-duration: 28388ms;
    -webkit-animation-delay: 23191ms;
            animation-delay: 23191ms;
  }
  @-webkit-keyframes move-frames-136 {
    from {
      transform: translate3d(37vw, 104vh, 0);
    }
    to {
      transform: translate3d(82vw, -106vh, 0);
    }
  }
  @keyframes move-frames-136 {
    from {
      transform: translate3d(37vw, 104vh, 0);
    }
    to {
      transform: translate3d(82vw, -106vh, 0);
    }
  }
  .circle-container:nth-child(136) .circle {
    -webkit-animation-delay: 3451ms;
            animation-delay: 3451ms;
  }
  .circle-container:nth-child(137) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-137;
            animation-name: move-frames-137;
    -webkit-animation-duration: 31438ms;
            animation-duration: 31438ms;
    -webkit-animation-delay: 27822ms;
            animation-delay: 27822ms;
  }
  @-webkit-keyframes move-frames-137 {
    from {
      transform: translate3d(20vw, 103vh, 0);
    }
    to {
      transform: translate3d(58vw, -105vh, 0);
    }
  }
  @keyframes move-frames-137 {
    from {
      transform: translate3d(20vw, 103vh, 0);
    }
    to {
      transform: translate3d(58vw, -105vh, 0);
    }
  }
  .circle-container:nth-child(137) .circle {
    -webkit-animation-delay: 694ms;
            animation-delay: 694ms;
  }
  .circle-container:nth-child(138) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-138;
            animation-name: move-frames-138;
    -webkit-animation-duration: 29981ms;
            animation-duration: 29981ms;
    -webkit-animation-delay: 23380ms;
            animation-delay: 23380ms;
  }
  @-webkit-keyframes move-frames-138 {
    from {
      transform: translate3d(83vw, 110vh, 0);
    }
    to {
      transform: translate3d(23vw, -139vh, 0);
    }
  }
  @keyframes move-frames-138 {
    from {
      transform: translate3d(83vw, 110vh, 0);
    }
    to {
      transform: translate3d(23vw, -139vh, 0);
    }
  }
  .circle-container:nth-child(138) .circle {
    -webkit-animation-delay: 3936ms;
            animation-delay: 3936ms;
  }
  .circle-container:nth-child(139) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-139;
            animation-name: move-frames-139;
    -webkit-animation-duration: 28137ms;
            animation-duration: 28137ms;
    -webkit-animation-delay: 33153ms;
            animation-delay: 33153ms;
  }
  @-webkit-keyframes move-frames-139 {
    from {
      transform: translate3d(30vw, 104vh, 0);
    }
    to {
      transform: translate3d(4vw, -121vh, 0);
    }
  }
  @keyframes move-frames-139 {
    from {
      transform: translate3d(30vw, 104vh, 0);
    }
    to {
      transform: translate3d(4vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(139) .circle {
    -webkit-animation-delay: 485ms;
            animation-delay: 485ms;
  }
  .circle-container:nth-child(140) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-140;
            animation-name: move-frames-140;
    -webkit-animation-duration: 36233ms;
            animation-duration: 36233ms;
    -webkit-animation-delay: 17284ms;
            animation-delay: 17284ms;
  }
  @-webkit-keyframes move-frames-140 {
    from {
      transform: translate3d(2vw, 107vh, 0);
    }
    to {
      transform: translate3d(49vw, -131vh, 0);
    }
  }
  @keyframes move-frames-140 {
    from {
      transform: translate3d(2vw, 107vh, 0);
    }
    to {
      transform: translate3d(49vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(140) .circle {
    -webkit-animation-delay: 862ms;
            animation-delay: 862ms;
  }
  .circle-container:nth-child(141) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-141;
            animation-name: move-frames-141;
    -webkit-animation-duration: 30007ms;
            animation-duration: 30007ms;
    -webkit-animation-delay: 12704ms;
            animation-delay: 12704ms;
  }
  @-webkit-keyframes move-frames-141 {
    from {
      transform: translate3d(89vw, 104vh, 0);
    }
    to {
      transform: translate3d(34vw, -132vh, 0);
    }
  }
  @keyframes move-frames-141 {
    from {
      transform: translate3d(89vw, 104vh, 0);
    }
    to {
      transform: translate3d(34vw, -132vh, 0);
    }
  }
  .circle-container:nth-child(141) .circle {
    -webkit-animation-delay: 2462ms;
            animation-delay: 2462ms;
  }
  .circle-container:nth-child(142) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-142;
            animation-name: move-frames-142;
    -webkit-animation-duration: 28131ms;
            animation-duration: 28131ms;
    -webkit-animation-delay: 36986ms;
            animation-delay: 36986ms;
  }
  @-webkit-keyframes move-frames-142 {
    from {
      transform: translate3d(34vw, 110vh, 0);
    }
    to {
      transform: translate3d(41vw, -135vh, 0);
    }
  }
  @keyframes move-frames-142 {
    from {
      transform: translate3d(34vw, 110vh, 0);
    }
    to {
      transform: translate3d(41vw, -135vh, 0);
    }
  }
  .circle-container:nth-child(142) .circle {
    -webkit-animation-delay: 667ms;
            animation-delay: 667ms;
  }
  .circle-container:nth-child(143) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-143;
            animation-name: move-frames-143;
    -webkit-animation-duration: 29249ms;
            animation-duration: 29249ms;
    -webkit-animation-delay: 34365ms;
            animation-delay: 34365ms;
  }
  @-webkit-keyframes move-frames-143 {
    from {
      transform: translate3d(64vw, 101vh, 0);
    }
    to {
      transform: translate3d(34vw, -123vh, 0);
    }
  }
  @keyframes move-frames-143 {
    from {
      transform: translate3d(64vw, 101vh, 0);
    }
    to {
      transform: translate3d(34vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(143) .circle {
    -webkit-animation-delay: 771ms;
            animation-delay: 771ms;
  }
  .circle-container:nth-child(144) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-144;
            animation-name: move-frames-144;
    -webkit-animation-duration: 33685ms;
            animation-duration: 33685ms;
    -webkit-animation-delay: 1425ms;
            animation-delay: 1425ms;
  }
  @-webkit-keyframes move-frames-144 {
    from {
      transform: translate3d(68vw, 105vh, 0);
    }
    to {
      transform: translate3d(62vw, -124vh, 0);
    }
  }
  @keyframes move-frames-144 {
    from {
      transform: translate3d(68vw, 105vh, 0);
    }
    to {
      transform: translate3d(62vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(144) .circle {
    -webkit-animation-delay: 3776ms;
            animation-delay: 3776ms;
  }
  .circle-container:nth-child(145) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-145;
            animation-name: move-frames-145;
    -webkit-animation-duration: 32482ms;
            animation-duration: 32482ms;
    -webkit-animation-delay: 29118ms;
            animation-delay: 29118ms;
  }
  @-webkit-keyframes move-frames-145 {
    from {
      transform: translate3d(31vw, 105vh, 0);
    }
    to {
      transform: translate3d(84vw, -124vh, 0);
    }
  }
  @keyframes move-frames-145 {
    from {
      transform: translate3d(31vw, 105vh, 0);
    }
    to {
      transform: translate3d(84vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(145) .circle {
    -webkit-animation-delay: 660ms;
            animation-delay: 660ms;
  }
  .circle-container:nth-child(146) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-146;
            animation-name: move-frames-146;
    -webkit-animation-duration: 31557ms;
            animation-duration: 31557ms;
    -webkit-animation-delay: 27877ms;
            animation-delay: 27877ms;
  }
  @-webkit-keyframes move-frames-146 {
    from {
      transform: translate3d(43vw, 102vh, 0);
    }
    to {
      transform: translate3d(67vw, -113vh, 0);
    }
  }
  @keyframes move-frames-146 {
    from {
      transform: translate3d(43vw, 102vh, 0);
    }
    to {
      transform: translate3d(67vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(146) .circle {
    -webkit-animation-delay: 1155ms;
            animation-delay: 1155ms;
  }
  .circle-container:nth-child(147) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-147;
            animation-name: move-frames-147;
    -webkit-animation-duration: 31617ms;
            animation-duration: 31617ms;
    -webkit-animation-delay: 10705ms;
            animation-delay: 10705ms;
  }
  @-webkit-keyframes move-frames-147 {
    from {
      transform: translate3d(42vw, 103vh, 0);
    }
    to {
      transform: translate3d(73vw, -121vh, 0);
    }
  }
  @keyframes move-frames-147 {
    from {
      transform: translate3d(42vw, 103vh, 0);
    }
    to {
      transform: translate3d(73vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(147) .circle {
    -webkit-animation-delay: 2973ms;
            animation-delay: 2973ms;
  }
  .circle-container:nth-child(148) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-148;
            animation-name: move-frames-148;
    -webkit-animation-duration: 34503ms;
            animation-duration: 34503ms;
    -webkit-animation-delay: 20403ms;
            animation-delay: 20403ms;
  }
  @-webkit-keyframes move-frames-148 {
    from {
      transform: translate3d(15vw, 108vh, 0);
    }
    to {
      transform: translate3d(23vw, -121vh, 0);
    }
  }
  @keyframes move-frames-148 {
    from {
      transform: translate3d(15vw, 108vh, 0);
    }
    to {
      transform: translate3d(23vw, -121vh, 0);
    }
  }
  .circle-container:nth-child(148) .circle {
    -webkit-animation-delay: 2054ms;
            animation-delay: 2054ms;
  }
  .circle-container:nth-child(149) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-149;
            animation-name: move-frames-149;
    -webkit-animation-duration: 34032ms;
            animation-duration: 34032ms;
    -webkit-animation-delay: 23602ms;
            animation-delay: 23602ms;
  }
  @-webkit-keyframes move-frames-149 {
    from {
      transform: translate3d(88vw, 110vh, 0);
    }
    to {
      transform: translate3d(49vw, -131vh, 0);
    }
  }
  @keyframes move-frames-149 {
    from {
      transform: translate3d(88vw, 110vh, 0);
    }
    to {
      transform: translate3d(49vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(149) .circle {
    -webkit-animation-delay: 399ms;
            animation-delay: 399ms;
  }
  .circle-container:nth-child(150) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-150;
            animation-name: move-frames-150;
    -webkit-animation-duration: 34017ms;
            animation-duration: 34017ms;
    -webkit-animation-delay: 20005ms;
            animation-delay: 20005ms;
  }
  @-webkit-keyframes move-frames-150 {
    from {
      transform: translate3d(74vw, 107vh, 0);
    }
    to {
      transform: translate3d(96vw, -127vh, 0);
    }
  }
  @keyframes move-frames-150 {
    from {
      transform: translate3d(74vw, 107vh, 0);
    }
    to {
      transform: translate3d(96vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(150) .circle {
    -webkit-animation-delay: 3563ms;
            animation-delay: 3563ms;
  }
  .circle-container:nth-child(151) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-151;
            animation-name: move-frames-151;
    -webkit-animation-duration: 34535ms;
            animation-duration: 34535ms;
    -webkit-animation-delay: 14037ms;
            animation-delay: 14037ms;
  }
  @-webkit-keyframes move-frames-151 {
    from {
      transform: translate3d(45vw, 101vh, 0);
    }
    to {
      transform: translate3d(27vw, -123vh, 0);
    }
  }
  @keyframes move-frames-151 {
    from {
      transform: translate3d(45vw, 101vh, 0);
    }
    to {
      transform: translate3d(27vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(151) .circle {
    -webkit-animation-delay: 2996ms;
            animation-delay: 2996ms;
  }
  .circle-container:nth-child(152) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-152;
            animation-name: move-frames-152;
    -webkit-animation-duration: 29250ms;
            animation-duration: 29250ms;
    -webkit-animation-delay: 1458ms;
            animation-delay: 1458ms;
  }
  @-webkit-keyframes move-frames-152 {
    from {
      transform: translate3d(6vw, 109vh, 0);
    }
    to {
      transform: translate3d(29vw, -119vh, 0);
    }
  }
  @keyframes move-frames-152 {
    from {
      transform: translate3d(6vw, 109vh, 0);
    }
    to {
      transform: translate3d(29vw, -119vh, 0);
    }
  }
  .circle-container:nth-child(152) .circle {
    -webkit-animation-delay: 3708ms;
            animation-delay: 3708ms;
  }
  .circle-container:nth-child(153) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-153;
            animation-name: move-frames-153;
    -webkit-animation-duration: 31867ms;
            animation-duration: 31867ms;
    -webkit-animation-delay: 12431ms;
            animation-delay: 12431ms;
  }
  @-webkit-keyframes move-frames-153 {
    from {
      transform: translate3d(29vw, 101vh, 0);
    }
    to {
      transform: translate3d(29vw, -115vh, 0);
    }
  }
  @keyframes move-frames-153 {
    from {
      transform: translate3d(29vw, 101vh, 0);
    }
    to {
      transform: translate3d(29vw, -115vh, 0);
    }
  }
  .circle-container:nth-child(153) .circle {
    -webkit-animation-delay: 1415ms;
            animation-delay: 1415ms;
  }
  .circle-container:nth-child(154) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-154;
            animation-name: move-frames-154;
    -webkit-animation-duration: 35524ms;
            animation-duration: 35524ms;
    -webkit-animation-delay: 31790ms;
            animation-delay: 31790ms;
  }
  @-webkit-keyframes move-frames-154 {
    from {
      transform: translate3d(50vw, 107vh, 0);
    }
    to {
      transform: translate3d(87vw, -118vh, 0);
    }
  }
  @keyframes move-frames-154 {
    from {
      transform: translate3d(50vw, 107vh, 0);
    }
    to {
      transform: translate3d(87vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(154) .circle {
    -webkit-animation-delay: 2482ms;
            animation-delay: 2482ms;
  }
  .circle-container:nth-child(155) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-155;
            animation-name: move-frames-155;
    -webkit-animation-duration: 35933ms;
            animation-duration: 35933ms;
    -webkit-animation-delay: 1575ms;
            animation-delay: 1575ms;
  }
  @-webkit-keyframes move-frames-155 {
    from {
      transform: translate3d(55vw, 103vh, 0);
    }
    to {
      transform: translate3d(57vw, -124vh, 0);
    }
  }
  @keyframes move-frames-155 {
    from {
      transform: translate3d(55vw, 103vh, 0);
    }
    to {
      transform: translate3d(57vw, -124vh, 0);
    }
  }
  .circle-container:nth-child(155) .circle {
    -webkit-animation-delay: 778ms;
            animation-delay: 778ms;
  }
  .circle-container:nth-child(156) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-156;
            animation-name: move-frames-156;
    -webkit-animation-duration: 29881ms;
            animation-duration: 29881ms;
    -webkit-animation-delay: 35816ms;
            animation-delay: 35816ms;
  }
  @-webkit-keyframes move-frames-156 {
    from {
      transform: translate3d(65vw, 110vh, 0);
    }
    to {
      transform: translate3d(2vw, -111vh, 0);
    }
  }
  @keyframes move-frames-156 {
    from {
      transform: translate3d(65vw, 110vh, 0);
    }
    to {
      transform: translate3d(2vw, -111vh, 0);
    }
  }
  .circle-container:nth-child(156) .circle {
    -webkit-animation-delay: 2600ms;
            animation-delay: 2600ms;
  }
  .circle-container:nth-child(157) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-157;
            animation-name: move-frames-157;
    -webkit-animation-duration: 34216ms;
            animation-duration: 34216ms;
    -webkit-animation-delay: 2685ms;
            animation-delay: 2685ms;
  }
  @-webkit-keyframes move-frames-157 {
    from {
      transform: translate3d(2vw, 102vh, 0);
    }
    to {
      transform: translate3d(63vw, -128vh, 0);
    }
  }
  @keyframes move-frames-157 {
    from {
      transform: translate3d(2vw, 102vh, 0);
    }
    to {
      transform: translate3d(63vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(157) .circle {
    -webkit-animation-delay: 3758ms;
            animation-delay: 3758ms;
  }
  .circle-container:nth-child(158) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-158;
            animation-name: move-frames-158;
    -webkit-animation-duration: 28753ms;
            animation-duration: 28753ms;
    -webkit-animation-delay: 28255ms;
            animation-delay: 28255ms;
  }
  @-webkit-keyframes move-frames-158 {
    from {
      transform: translate3d(99vw, 103vh, 0);
    }
    to {
      transform: translate3d(69vw, -133vh, 0);
    }
  }
  @keyframes move-frames-158 {
    from {
      transform: translate3d(99vw, 103vh, 0);
    }
    to {
      transform: translate3d(69vw, -133vh, 0);
    }
  }
  .circle-container:nth-child(158) .circle {
    -webkit-animation-delay: 1615ms;
            animation-delay: 1615ms;
  }
  .circle-container:nth-child(159) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-159;
            animation-name: move-frames-159;
    -webkit-animation-duration: 28917ms;
            animation-duration: 28917ms;
    -webkit-animation-delay: 16258ms;
            animation-delay: 16258ms;
  }
  @-webkit-keyframes move-frames-159 {
    from {
      transform: translate3d(22vw, 109vh, 0);
    }
    to {
      transform: translate3d(95vw, -112vh, 0);
    }
  }
  @keyframes move-frames-159 {
    from {
      transform: translate3d(22vw, 109vh, 0);
    }
    to {
      transform: translate3d(95vw, -112vh, 0);
    }
  }
  .circle-container:nth-child(159) .circle {
    -webkit-animation-delay: 2346ms;
            animation-delay: 2346ms;
  }
  .circle-container:nth-child(160) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-160;
            animation-name: move-frames-160;
    -webkit-animation-duration: 30969ms;
            animation-duration: 30969ms;
    -webkit-animation-delay: 3548ms;
            animation-delay: 3548ms;
  }
  @-webkit-keyframes move-frames-160 {
    from {
      transform: translate3d(14vw, 103vh, 0);
    }
    to {
      transform: translate3d(96vw, -107vh, 0);
    }
  }
  @keyframes move-frames-160 {
    from {
      transform: translate3d(14vw, 103vh, 0);
    }
    to {
      transform: translate3d(96vw, -107vh, 0);
    }
  }
  .circle-container:nth-child(160) .circle {
    -webkit-animation-delay: 2710ms;
            animation-delay: 2710ms;
  }
  .circle-container:nth-child(161) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-161;
            animation-name: move-frames-161;
    -webkit-animation-duration: 36851ms;
            animation-duration: 36851ms;
    -webkit-animation-delay: 2801ms;
            animation-delay: 2801ms;
  }
  @-webkit-keyframes move-frames-161 {
    from {
      transform: translate3d(54vw, 109vh, 0);
    }
    to {
      transform: translate3d(57vw, -137vh, 0);
    }
  }
  @keyframes move-frames-161 {
    from {
      transform: translate3d(54vw, 109vh, 0);
    }
    to {
      transform: translate3d(57vw, -137vh, 0);
    }
  }
  .circle-container:nth-child(161) .circle {
    -webkit-animation-delay: 989ms;
            animation-delay: 989ms;
  }
  .circle-container:nth-child(162) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-162;
            animation-name: move-frames-162;
    -webkit-animation-duration: 33135ms;
            animation-duration: 33135ms;
    -webkit-animation-delay: 15747ms;
            animation-delay: 15747ms;
  }
  @-webkit-keyframes move-frames-162 {
    from {
      transform: translate3d(81vw, 105vh, 0);
    }
    to {
      transform: translate3d(68vw, -106vh, 0);
    }
  }
  @keyframes move-frames-162 {
    from {
      transform: translate3d(81vw, 105vh, 0);
    }
    to {
      transform: translate3d(68vw, -106vh, 0);
    }
  }
  .circle-container:nth-child(162) .circle {
    -webkit-animation-delay: 2818ms;
            animation-delay: 2818ms;
  }
  .circle-container:nth-child(163) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-163;
            animation-name: move-frames-163;
    -webkit-animation-duration: 34271ms;
            animation-duration: 34271ms;
    -webkit-animation-delay: 6637ms;
            animation-delay: 6637ms;
  }
  @-webkit-keyframes move-frames-163 {
    from {
      transform: translate3d(6vw, 106vh, 0);
    }
    to {
      transform: translate3d(55vw, -113vh, 0);
    }
  }
  @keyframes move-frames-163 {
    from {
      transform: translate3d(6vw, 106vh, 0);
    }
    to {
      transform: translate3d(55vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(163) .circle {
    -webkit-animation-delay: 2990ms;
            animation-delay: 2990ms;
  }
  .circle-container:nth-child(164) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-164;
            animation-name: move-frames-164;
    -webkit-animation-duration: 34478ms;
            animation-duration: 34478ms;
    -webkit-animation-delay: 1974ms;
            animation-delay: 1974ms;
  }
  @-webkit-keyframes move-frames-164 {
    from {
      transform: translate3d(12vw, 109vh, 0);
    }
    to {
      transform: translate3d(30vw, -133vh, 0);
    }
  }
  @keyframes move-frames-164 {
    from {
      transform: translate3d(12vw, 109vh, 0);
    }
    to {
      transform: translate3d(30vw, -133vh, 0);
    }
  }
  .circle-container:nth-child(164) .circle {
    -webkit-animation-delay: 1873ms;
            animation-delay: 1873ms;
  }
  .circle-container:nth-child(165) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-165;
            animation-name: move-frames-165;
    -webkit-animation-duration: 31799ms;
            animation-duration: 31799ms;
    -webkit-animation-delay: 35351ms;
            animation-delay: 35351ms;
  }
  @-webkit-keyframes move-frames-165 {
    from {
      transform: translate3d(70vw, 101vh, 0);
    }
    to {
      transform: translate3d(36vw, -123vh, 0);
    }
  }
  @keyframes move-frames-165 {
    from {
      transform: translate3d(70vw, 101vh, 0);
    }
    to {
      transform: translate3d(36vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(165) .circle {
    -webkit-animation-delay: 566ms;
            animation-delay: 566ms;
  }
  .circle-container:nth-child(166) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-166;
            animation-name: move-frames-166;
    -webkit-animation-duration: 29200ms;
            animation-duration: 29200ms;
    -webkit-animation-delay: 28596ms;
            animation-delay: 28596ms;
  }
  @-webkit-keyframes move-frames-166 {
    from {
      transform: translate3d(98vw, 107vh, 0);
    }
    to {
      transform: translate3d(25vw, -127vh, 0);
    }
  }
  @keyframes move-frames-166 {
    from {
      transform: translate3d(98vw, 107vh, 0);
    }
    to {
      transform: translate3d(25vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(166) .circle {
    -webkit-animation-delay: 557ms;
            animation-delay: 557ms;
  }
  .circle-container:nth-child(167) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-167;
            animation-name: move-frames-167;
    -webkit-animation-duration: 30795ms;
            animation-duration: 30795ms;
    -webkit-animation-delay: 19231ms;
            animation-delay: 19231ms;
  }
  @-webkit-keyframes move-frames-167 {
    from {
      transform: translate3d(52vw, 108vh, 0);
    }
    to {
      transform: translate3d(100vw, -127vh, 0);
    }
  }
  @keyframes move-frames-167 {
    from {
      transform: translate3d(52vw, 108vh, 0);
    }
    to {
      transform: translate3d(100vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(167) .circle {
    -webkit-animation-delay: 2061ms;
            animation-delay: 2061ms;
  }
  .circle-container:nth-child(168) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-168;
            animation-name: move-frames-168;
    -webkit-animation-duration: 34580ms;
            animation-duration: 34580ms;
    -webkit-animation-delay: 4761ms;
            animation-delay: 4761ms;
  }
  @-webkit-keyframes move-frames-168 {
    from {
      transform: translate3d(26vw, 106vh, 0);
    }
    to {
      transform: translate3d(76vw, -119vh, 0);
    }
  }
  @keyframes move-frames-168 {
    from {
      transform: translate3d(26vw, 106vh, 0);
    }
    to {
      transform: translate3d(76vw, -119vh, 0);
    }
  }
  .circle-container:nth-child(168) .circle {
    -webkit-animation-delay: 2709ms;
            animation-delay: 2709ms;
  }
  .circle-container:nth-child(169) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-169;
            animation-name: move-frames-169;
    -webkit-animation-duration: 31591ms;
            animation-duration: 31591ms;
    -webkit-animation-delay: 10902ms;
            animation-delay: 10902ms;
  }
  @-webkit-keyframes move-frames-169 {
    from {
      transform: translate3d(22vw, 106vh, 0);
    }
    to {
      transform: translate3d(29vw, -131vh, 0);
    }
  }
  @keyframes move-frames-169 {
    from {
      transform: translate3d(22vw, 106vh, 0);
    }
    to {
      transform: translate3d(29vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(169) .circle {
    -webkit-animation-delay: 3460ms;
            animation-delay: 3460ms;
  }
  .circle-container:nth-child(170) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-170;
            animation-name: move-frames-170;
    -webkit-animation-duration: 28893ms;
            animation-duration: 28893ms;
    -webkit-animation-delay: 11112ms;
            animation-delay: 11112ms;
  }
  @-webkit-keyframes move-frames-170 {
    from {
      transform: translate3d(74vw, 106vh, 0);
    }
    to {
      transform: translate3d(79vw, -134vh, 0);
    }
  }
  @keyframes move-frames-170 {
    from {
      transform: translate3d(74vw, 106vh, 0);
    }
    to {
      transform: translate3d(79vw, -134vh, 0);
    }
  }
  .circle-container:nth-child(170) .circle {
    -webkit-animation-delay: 1712ms;
            animation-delay: 1712ms;
  }
  .circle-container:nth-child(171) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-171;
            animation-name: move-frames-171;
    -webkit-animation-duration: 28297ms;
            animation-duration: 28297ms;
    -webkit-animation-delay: 12594ms;
            animation-delay: 12594ms;
  }
  @-webkit-keyframes move-frames-171 {
    from {
      transform: translate3d(96vw, 101vh, 0);
    }
    to {
      transform: translate3d(80vw, -119vh, 0);
    }
  }
  @keyframes move-frames-171 {
    from {
      transform: translate3d(96vw, 101vh, 0);
    }
    to {
      transform: translate3d(80vw, -119vh, 0);
    }
  }
  .circle-container:nth-child(171) .circle {
    -webkit-animation-delay: 3723ms;
            animation-delay: 3723ms;
  }
  .circle-container:nth-child(172) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-172;
            animation-name: move-frames-172;
    -webkit-animation-duration: 32186ms;
            animation-duration: 32186ms;
    -webkit-animation-delay: 7328ms;
            animation-delay: 7328ms;
  }
  @-webkit-keyframes move-frames-172 {
    from {
      transform: translate3d(28vw, 104vh, 0);
    }
    to {
      transform: translate3d(19vw, -129vh, 0);
    }
  }
  @keyframes move-frames-172 {
    from {
      transform: translate3d(28vw, 104vh, 0);
    }
    to {
      transform: translate3d(19vw, -129vh, 0);
    }
  }
  .circle-container:nth-child(172) .circle {
    -webkit-animation-delay: 2755ms;
            animation-delay: 2755ms;
  }
  .circle-container:nth-child(173) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-173;
            animation-name: move-frames-173;
    -webkit-animation-duration: 32241ms;
            animation-duration: 32241ms;
    -webkit-animation-delay: 9371ms;
            animation-delay: 9371ms;
  }
  @-webkit-keyframes move-frames-173 {
    from {
      transform: translate3d(55vw, 109vh, 0);
    }
    to {
      transform: translate3d(61vw, -111vh, 0);
    }
  }
  @keyframes move-frames-173 {
    from {
      transform: translate3d(55vw, 109vh, 0);
    }
    to {
      transform: translate3d(61vw, -111vh, 0);
    }
  }
  .circle-container:nth-child(173) .circle {
    -webkit-animation-delay: 2622ms;
            animation-delay: 2622ms;
  }
  .circle-container:nth-child(174) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-174;
            animation-name: move-frames-174;
    -webkit-animation-duration: 35058ms;
            animation-duration: 35058ms;
    -webkit-animation-delay: 852ms;
            animation-delay: 852ms;
  }
  @-webkit-keyframes move-frames-174 {
    from {
      transform: translate3d(89vw, 108vh, 0);
    }
    to {
      transform: translate3d(51vw, -126vh, 0);
    }
  }
  @keyframes move-frames-174 {
    from {
      transform: translate3d(89vw, 108vh, 0);
    }
    to {
      transform: translate3d(51vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(174) .circle {
    -webkit-animation-delay: 1758ms;
            animation-delay: 1758ms;
  }
  .circle-container:nth-child(175) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-175;
            animation-name: move-frames-175;
    -webkit-animation-duration: 31671ms;
            animation-duration: 31671ms;
    -webkit-animation-delay: 31264ms;
            animation-delay: 31264ms;
  }
  @-webkit-keyframes move-frames-175 {
    from {
      transform: translate3d(12vw, 102vh, 0);
    }
    to {
      transform: translate3d(90vw, -116vh, 0);
    }
  }
  @keyframes move-frames-175 {
    from {
      transform: translate3d(12vw, 102vh, 0);
    }
    to {
      transform: translate3d(90vw, -116vh, 0);
    }
  }
  .circle-container:nth-child(175) .circle {
    -webkit-animation-delay: 2599ms;
            animation-delay: 2599ms;
  }
  .circle-container:nth-child(176) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-176;
            animation-name: move-frames-176;
    -webkit-animation-duration: 28063ms;
            animation-duration: 28063ms;
    -webkit-animation-delay: 8810ms;
            animation-delay: 8810ms;
  }
  @-webkit-keyframes move-frames-176 {
    from {
      transform: translate3d(14vw, 105vh, 0);
    }
    to {
      transform: translate3d(99vw, -113vh, 0);
    }
  }
  @keyframes move-frames-176 {
    from {
      transform: translate3d(14vw, 105vh, 0);
    }
    to {
      transform: translate3d(99vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(176) .circle {
    -webkit-animation-delay: 1800ms;
            animation-delay: 1800ms;
  }
  .circle-container:nth-child(177) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-177;
            animation-name: move-frames-177;
    -webkit-animation-duration: 34141ms;
            animation-duration: 34141ms;
    -webkit-animation-delay: 34321ms;
            animation-delay: 34321ms;
  }
  @-webkit-keyframes move-frames-177 {
    from {
      transform: translate3d(8vw, 104vh, 0);
    }
    to {
      transform: translate3d(68vw, -127vh, 0);
    }
  }
  @keyframes move-frames-177 {
    from {
      transform: translate3d(8vw, 104vh, 0);
    }
    to {
      transform: translate3d(68vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(177) .circle {
    -webkit-animation-delay: 1909ms;
            animation-delay: 1909ms;
  }
  .circle-container:nth-child(178) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-178;
            animation-name: move-frames-178;
    -webkit-animation-duration: 36383ms;
            animation-duration: 36383ms;
    -webkit-animation-delay: 12095ms;
            animation-delay: 12095ms;
  }
  @-webkit-keyframes move-frames-178 {
    from {
      transform: translate3d(48vw, 110vh, 0);
    }
    to {
      transform: translate3d(48vw, -140vh, 0);
    }
  }
  @keyframes move-frames-178 {
    from {
      transform: translate3d(48vw, 110vh, 0);
    }
    to {
      transform: translate3d(48vw, -140vh, 0);
    }
  }
  .circle-container:nth-child(178) .circle {
    -webkit-animation-delay: 3482ms;
            animation-delay: 3482ms;
  }
  .circle-container:nth-child(179) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-179;
            animation-name: move-frames-179;
    -webkit-animation-duration: 35981ms;
            animation-duration: 35981ms;
    -webkit-animation-delay: 26139ms;
            animation-delay: 26139ms;
  }
  @-webkit-keyframes move-frames-179 {
    from {
      transform: translate3d(34vw, 101vh, 0);
    }
    to {
      transform: translate3d(32vw, -113vh, 0);
    }
  }
  @keyframes move-frames-179 {
    from {
      transform: translate3d(34vw, 101vh, 0);
    }
    to {
      transform: translate3d(32vw, -113vh, 0);
    }
  }
  .circle-container:nth-child(179) .circle {
    -webkit-animation-delay: 1535ms;
            animation-delay: 1535ms;
  }
  .circle-container:nth-child(180) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-180;
            animation-name: move-frames-180;
    -webkit-animation-duration: 33241ms;
            animation-duration: 33241ms;
    -webkit-animation-delay: 30497ms;
            animation-delay: 30497ms;
  }
  @-webkit-keyframes move-frames-180 {
    from {
      transform: translate3d(25vw, 107vh, 0);
    }
    to {
      transform: translate3d(73vw, -128vh, 0);
    }
  }
  @keyframes move-frames-180 {
    from {
      transform: translate3d(25vw, 107vh, 0);
    }
    to {
      transform: translate3d(73vw, -128vh, 0);
    }
  }
  .circle-container:nth-child(180) .circle {
    -webkit-animation-delay: 1804ms;
            animation-delay: 1804ms;
  }
  .circle-container:nth-child(181) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-181;
            animation-name: move-frames-181;
    -webkit-animation-duration: 33457ms;
            animation-duration: 33457ms;
    -webkit-animation-delay: 29785ms;
            animation-delay: 29785ms;
  }
  @-webkit-keyframes move-frames-181 {
    from {
      transform: translate3d(22vw, 104vh, 0);
    }
    to {
      transform: translate3d(76vw, -129vh, 0);
    }
  }
  @keyframes move-frames-181 {
    from {
      transform: translate3d(22vw, 104vh, 0);
    }
    to {
      transform: translate3d(76vw, -129vh, 0);
    }
  }
  .circle-container:nth-child(181) .circle {
    -webkit-animation-delay: 2147ms;
            animation-delay: 2147ms;
  }
  .circle-container:nth-child(182) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-182;
            animation-name: move-frames-182;
    -webkit-animation-duration: 29393ms;
            animation-duration: 29393ms;
    -webkit-animation-delay: 29021ms;
            animation-delay: 29021ms;
  }
  @-webkit-keyframes move-frames-182 {
    from {
      transform: translate3d(22vw, 105vh, 0);
    }
    to {
      transform: translate3d(40vw, -108vh, 0);
    }
  }
  @keyframes move-frames-182 {
    from {
      transform: translate3d(22vw, 105vh, 0);
    }
    to {
      transform: translate3d(40vw, -108vh, 0);
    }
  }
  .circle-container:nth-child(182) .circle {
    -webkit-animation-delay: 1502ms;
            animation-delay: 1502ms;
  }
  .circle-container:nth-child(183) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-183;
            animation-name: move-frames-183;
    -webkit-animation-duration: 35790ms;
            animation-duration: 35790ms;
    -webkit-animation-delay: 14067ms;
            animation-delay: 14067ms;
  }
  @-webkit-keyframes move-frames-183 {
    from {
      transform: translate3d(73vw, 101vh, 0);
    }
    to {
      transform: translate3d(47vw, -117vh, 0);
    }
  }
  @keyframes move-frames-183 {
    from {
      transform: translate3d(73vw, 101vh, 0);
    }
    to {
      transform: translate3d(47vw, -117vh, 0);
    }
  }
  .circle-container:nth-child(183) .circle {
    -webkit-animation-delay: 1734ms;
            animation-delay: 1734ms;
  }
  .circle-container:nth-child(184) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-184;
            animation-name: move-frames-184;
    -webkit-animation-duration: 32482ms;
            animation-duration: 32482ms;
    -webkit-animation-delay: 6964ms;
            animation-delay: 6964ms;
  }
  @-webkit-keyframes move-frames-184 {
    from {
      transform: translate3d(91vw, 106vh, 0);
    }
    to {
      transform: translate3d(46vw, -120vh, 0);
    }
  }
  @keyframes move-frames-184 {
    from {
      transform: translate3d(91vw, 106vh, 0);
    }
    to {
      transform: translate3d(46vw, -120vh, 0);
    }
  }
  .circle-container:nth-child(184) .circle {
    -webkit-animation-delay: 1597ms;
            animation-delay: 1597ms;
  }
  .circle-container:nth-child(185) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-185;
            animation-name: move-frames-185;
    -webkit-animation-duration: 36475ms;
            animation-duration: 36475ms;
    -webkit-animation-delay: 16275ms;
            animation-delay: 16275ms;
  }
  @-webkit-keyframes move-frames-185 {
    from {
      transform: translate3d(58vw, 102vh, 0);
    }
    to {
      transform: translate3d(62vw, -115vh, 0);
    }
  }
  @keyframes move-frames-185 {
    from {
      transform: translate3d(58vw, 102vh, 0);
    }
    to {
      transform: translate3d(62vw, -115vh, 0);
    }
  }
  .circle-container:nth-child(185) .circle {
    -webkit-animation-delay: 2534ms;
            animation-delay: 2534ms;
  }
  .circle-container:nth-child(186) {
    width: 6px;
    height: 6px;
    -webkit-animation-name: move-frames-186;
            animation-name: move-frames-186;
    -webkit-animation-duration: 34983ms;
            animation-duration: 34983ms;
    -webkit-animation-delay: 27605ms;
            animation-delay: 27605ms;
  }
  @-webkit-keyframes move-frames-186 {
    from {
      transform: translate3d(11vw, 106vh, 0);
    }
    to {
      transform: translate3d(57vw, -133vh, 0);
    }
  }
  @keyframes move-frames-186 {
    from {
      transform: translate3d(11vw, 106vh, 0);
    }
    to {
      transform: translate3d(57vw, -133vh, 0);
    }
  }
  .circle-container:nth-child(186) .circle {
    -webkit-animation-delay: 1194ms;
            animation-delay: 1194ms;
  }
  .circle-container:nth-child(187) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-187;
            animation-name: move-frames-187;
    -webkit-animation-duration: 29804ms;
            animation-duration: 29804ms;
    -webkit-animation-delay: 24126ms;
            animation-delay: 24126ms;
  }
  @-webkit-keyframes move-frames-187 {
    from {
      transform: translate3d(70vw, 109vh, 0);
    }
    to {
      transform: translate3d(71vw, -133vh, 0);
    }
  }
  @keyframes move-frames-187 {
    from {
      transform: translate3d(70vw, 109vh, 0);
    }
    to {
      transform: translate3d(71vw, -133vh, 0);
    }
  }
  .circle-container:nth-child(187) .circle {
    -webkit-animation-delay: 1258ms;
            animation-delay: 1258ms;
  }
  .circle-container:nth-child(188) {
    width: 7px;
    height: 7px;
    -webkit-animation-name: move-frames-188;
            animation-name: move-frames-188;
    -webkit-animation-duration: 34375ms;
            animation-duration: 34375ms;
    -webkit-animation-delay: 15008ms;
            animation-delay: 15008ms;
  }
  @-webkit-keyframes move-frames-188 {
    from {
      transform: translate3d(91vw, 110vh, 0);
    }
    to {
      transform: translate3d(10vw, -140vh, 0);
    }
  }
  @keyframes move-frames-188 {
    from {
      transform: translate3d(91vw, 110vh, 0);
    }
    to {
      transform: translate3d(10vw, -140vh, 0);
    }
  }
  .circle-container:nth-child(188) .circle {
    -webkit-animation-delay: 3427ms;
            animation-delay: 3427ms;
  }
  .circle-container:nth-child(189) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-189;
            animation-name: move-frames-189;
    -webkit-animation-duration: 29133ms;
            animation-duration: 29133ms;
    -webkit-animation-delay: 33933ms;
            animation-delay: 33933ms;
  }
  @-webkit-keyframes move-frames-189 {
    from {
      transform: translate3d(22vw, 107vh, 0);
    }
    to {
      transform: translate3d(96vw, -131vh, 0);
    }
  }
  @keyframes move-frames-189 {
    from {
      transform: translate3d(22vw, 107vh, 0);
    }
    to {
      transform: translate3d(96vw, -131vh, 0);
    }
  }
  .circle-container:nth-child(189) .circle {
    -webkit-animation-delay: 205ms;
            animation-delay: 205ms;
  }
  .circle-container:nth-child(190) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-190;
            animation-name: move-frames-190;
    -webkit-animation-duration: 29762ms;
            animation-duration: 29762ms;
    -webkit-animation-delay: 35071ms;
            animation-delay: 35071ms;
  }
  @-webkit-keyframes move-frames-190 {
    from {
      transform: translate3d(47vw, 106vh, 0);
    }
    to {
      transform: translate3d(40vw, -126vh, 0);
    }
  }
  @keyframes move-frames-190 {
    from {
      transform: translate3d(47vw, 106vh, 0);
    }
    to {
      transform: translate3d(40vw, -126vh, 0);
    }
  }
  .circle-container:nth-child(190) .circle {
    -webkit-animation-delay: 2044ms;
            animation-delay: 2044ms;
  }
  .circle-container:nth-child(191) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-191;
            animation-name: move-frames-191;
    -webkit-animation-duration: 35406ms;
            animation-duration: 35406ms;
    -webkit-animation-delay: 25878ms;
            animation-delay: 25878ms;
  }
  @-webkit-keyframes move-frames-191 {
    from {
      transform: translate3d(93vw, 106vh, 0);
    }
    to {
      transform: translate3d(79vw, -125vh, 0);
    }
  }
  @keyframes move-frames-191 {
    from {
      transform: translate3d(93vw, 106vh, 0);
    }
    to {
      transform: translate3d(79vw, -125vh, 0);
    }
  }
  .circle-container:nth-child(191) .circle {
    -webkit-animation-delay: 1147ms;
            animation-delay: 1147ms;
  }
  .circle-container:nth-child(192) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-192;
            animation-name: move-frames-192;
    -webkit-animation-duration: 32077ms;
            animation-duration: 32077ms;
    -webkit-animation-delay: 1288ms;
            animation-delay: 1288ms;
  }
  @-webkit-keyframes move-frames-192 {
    from {
      transform: translate3d(34vw, 102vh, 0);
    }
    to {
      transform: translate3d(25vw, -117vh, 0);
    }
  }
  @keyframes move-frames-192 {
    from {
      transform: translate3d(34vw, 102vh, 0);
    }
    to {
      transform: translate3d(25vw, -117vh, 0);
    }
  }
  .circle-container:nth-child(192) .circle {
    -webkit-animation-delay: 3706ms;
            animation-delay: 3706ms;
  }
  .circle-container:nth-child(193) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-193;
            animation-name: move-frames-193;
    -webkit-animation-duration: 35723ms;
            animation-duration: 35723ms;
    -webkit-animation-delay: 26164ms;
            animation-delay: 26164ms;
  }
  @-webkit-keyframes move-frames-193 {
    from {
      transform: translate3d(92vw, 102vh, 0);
    }
    to {
      transform: translate3d(1vw, -130vh, 0);
    }
  }
  @keyframes move-frames-193 {
    from {
      transform: translate3d(92vw, 102vh, 0);
    }
    to {
      transform: translate3d(1vw, -130vh, 0);
    }
  }
  .circle-container:nth-child(193) .circle {
    -webkit-animation-delay: 2761ms;
            animation-delay: 2761ms;
  }
  .circle-container:nth-child(194) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-194;
            animation-name: move-frames-194;
    -webkit-animation-duration: 28681ms;
            animation-duration: 28681ms;
    -webkit-animation-delay: 1949ms;
            animation-delay: 1949ms;
  }
  @-webkit-keyframes move-frames-194 {
    from {
      transform: translate3d(43vw, 109vh, 0);
    }
    to {
      transform: translate3d(91vw, -116vh, 0);
    }
  }
  @keyframes move-frames-194 {
    from {
      transform: translate3d(43vw, 109vh, 0);
    }
    to {
      transform: translate3d(91vw, -116vh, 0);
    }
  }
  .circle-container:nth-child(194) .circle {
    -webkit-animation-delay: 3888ms;
            animation-delay: 3888ms;
  }
  .circle-container:nth-child(195) {
    width: 3px;
    height: 3px;
    -webkit-animation-name: move-frames-195;
            animation-name: move-frames-195;
    -webkit-animation-duration: 35061ms;
            animation-duration: 35061ms;
    -webkit-animation-delay: 29458ms;
            animation-delay: 29458ms;
  }
  @-webkit-keyframes move-frames-195 {
    from {
      transform: translate3d(77vw, 109vh, 0);
    }
    to {
      transform: translate3d(75vw, -127vh, 0);
    }
  }
  @keyframes move-frames-195 {
    from {
      transform: translate3d(77vw, 109vh, 0);
    }
    to {
      transform: translate3d(75vw, -127vh, 0);
    }
  }
  .circle-container:nth-child(195) .circle {
    -webkit-animation-delay: 2397ms;
            animation-delay: 2397ms;
  }
  .circle-container:nth-child(196) {
    width: 1px;
    height: 1px;
    -webkit-animation-name: move-frames-196;
            animation-name: move-frames-196;
    -webkit-animation-duration: 33160ms;
            animation-duration: 33160ms;
    -webkit-animation-delay: 14774ms;
            animation-delay: 14774ms;
  }
  @-webkit-keyframes move-frames-196 {
    from {
      transform: translate3d(59vw, 106vh, 0);
    }
    to {
      transform: translate3d(63vw, -109vh, 0);
    }
  }
  @keyframes move-frames-196 {
    from {
      transform: translate3d(59vw, 106vh, 0);
    }
    to {
      transform: translate3d(63vw, -109vh, 0);
    }
  }
  .circle-container:nth-child(196) .circle {
    -webkit-animation-delay: 3363ms;
            animation-delay: 3363ms;
  }
  .circle-container:nth-child(197) {
    width: 8px;
    height: 8px;
    -webkit-animation-name: move-frames-197;
            animation-name: move-frames-197;
    -webkit-animation-duration: 34554ms;
            animation-duration: 34554ms;
    -webkit-animation-delay: 20687ms;
            animation-delay: 20687ms;
  }
  @-webkit-keyframes move-frames-197 {
    from {
      transform: translate3d(66vw, 107vh, 0);
    }
    to {
      transform: translate3d(63vw, -123vh, 0);
    }
  }
  @keyframes move-frames-197 {
    from {
      transform: translate3d(66vw, 107vh, 0);
    }
    to {
      transform: translate3d(63vw, -123vh, 0);
    }
  }
  .circle-container:nth-child(197) .circle {
    -webkit-animation-delay: 1417ms;
            animation-delay: 1417ms;
  }
  .circle-container:nth-child(198) {
    width: 5px;
    height: 5px;
    -webkit-animation-name: move-frames-198;
            animation-name: move-frames-198;
    -webkit-animation-duration: 35593ms;
            animation-duration: 35593ms;
    -webkit-animation-delay: 5130ms;
            animation-delay: 5130ms;
  }
  @-webkit-keyframes move-frames-198 {
    from {
      transform: translate3d(59vw, 109vh, 0);
    }
    to {
      transform: translate3d(12vw, -111vh, 0);
    }
  }
  @keyframes move-frames-198 {
    from {
      transform: translate3d(59vw, 109vh, 0);
    }
    to {
      transform: translate3d(12vw, -111vh, 0);
    }
  }
  .circle-container:nth-child(198) .circle {
    -webkit-animation-delay: 1416ms;
            animation-delay: 1416ms;
  }
  .circle-container:nth-child(199) {
    width: 4px;
    height: 4px;
    -webkit-animation-name: move-frames-199;
            animation-name: move-frames-199;
    -webkit-animation-duration: 29322ms;
            animation-duration: 29322ms;
    -webkit-animation-delay: 17536ms;
            animation-delay: 17536ms;
  }
  @-webkit-keyframes move-frames-199 {
    from {
      transform: translate3d(5vw, 108vh, 0);
    }
    to {
      transform: translate3d(15vw, -118vh, 0);
    }
  }
  @keyframes move-frames-199 {
    from {
      transform: translate3d(5vw, 108vh, 0);
    }
    to {
      transform: translate3d(15vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(199) .circle {
    -webkit-animation-delay: 2235ms;
            animation-delay: 2235ms;
  }
  .circle-container:nth-child(200) {
    width: 2px;
    height: 2px;
    -webkit-animation-name: move-frames-200;
            animation-name: move-frames-200;
    -webkit-animation-duration: 28551ms;
            animation-duration: 28551ms;
    -webkit-animation-delay: 32609ms;
            animation-delay: 32609ms;
  }
  @-webkit-keyframes move-frames-200 {
    from {
      transform: translate3d(13vw, 108vh, 0);
    }
    to {
      transform: translate3d(71vw, -118vh, 0);
    }
  }
  @keyframes move-frames-200 {
    from {
      transform: translate3d(13vw, 108vh, 0);
    }
    to {
      transform: translate3d(71vw, -118vh, 0);
    }
  }
  .circle-container:nth-child(200) .circle {
    -webkit-animation-delay: 3760ms;
            animation-delay: 3760ms;
  }
  
  .message {
    position: absolute;
    left: 2rem;
    top: 3rem;
    color: white;
    font-family: "Josefin Slab", serif;
    line-height: 27px;
    font-size: 18px;
    text-align: right;
    pointer-events: none;
    -webkit-animation: message-frames .5s ease .5s forwards;
            animation: message-frames .5s ease .5s forwards;
    opacity: 0;
  }
  @-webkit-keyframes message-frames {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes message-frames {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }