
.image-grid .image-div {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.image-grid .image-div>.overlay {
    position: absolute;
    top: 0;
    left: -100%;
    background-color: rgba(5, 5, 5, 0.616);
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    opacity: 0;
    color: white; 
}
.image-grid .image-div .text{
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    font-weight: 600;
    display: inline-block;
    clear: none;
    margin: 0px 120px 40px 40px;
    padding: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
    background: transparent;
    position: relative;
    z-index: 2 !important;
    font-family: "Roboto Serif", serif;
    position: relative;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
}
.image-grid .image-div .text>.icon {
    margin-left: 0.5rem !important;
    cursor: pointer;
}
.image-grid .image-div img {
    max-width: 100%;
}
.image-grid .image-div:hover .overlay {
    opacity: 1;
    left: 0;
}

.card {
    position: relative;
    height: 400px;
    width: 100%;
    margin: 10px 0;
    transition: ease all 2.3s;
    perspective: 1200px;
    background-color: transparent;
  }
  .card:hover .cover {
    transform: rotateX(0deg) rotateY(-180deg);
  }
  .card:hover .cover:before {
    transform: translateZ(30px);
  }
  .card:hover .cover:after {
    background-color: black;
  }
  .card:hover .cover h1 {
    transform: translateZ(100px);
  }
  .card:hover .cover .price {
    transform: translateZ(60px);
  }
  .card:hover .cover a {
    transform: translateZ(-60px) rotatey(-180deg);
  }
  .card .cover {
    position: absolute;
    height: 100%;
    width: 100%;
    transform-style: preserve-3d;
    transition: ease all 2.3s;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .card .cover:before {
    content: "";
    position: absolute;
    border: 5px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 2;
    transition: ease all 2.3s;
    transform-style: preserve-3d;
    transform: translateZ(0px);
  }
  .card .cover:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 2;
    transition: ease all 1.3s;
    background: rgba(0, 0, 0, 0.4);
  }
  .card .cover h1 {
    font-weight: 600;
    position: absolute;
    bottom: 55px;
    left: 50px;
    color: white;
    transform-style: preserve-3d;
    transition: ease all 2.3s;
    z-index: 3;
    font-size: 3em;
    transform: translateZ(0px);
  }
  .card .cover .price {
    font-weight: 200;
    position: absolute;
    top: 55px;
    right: 50px;
    color: white;
    transform-style: preserve-3d;
    transition: ease all 2.3s;
    z-index: 4;
    font-size: 2em;
    transform: translateZ(0px);
  }
  .card .card-back {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #0b0f08;
    transform-style: preserve-3d;
    transition: ease all 2.3s;
    transform: translateZ(-1px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card .card-back a {
    transform-style: preserve-3d;
    transition: ease transform 2.3s, ease background 0.5s;
    transform: translateZ(-1px) rotatey(-180deg);
    background: transparent;
    border: 1px solid white;
    font-weight: 200;
    font-size: 1.3em;
    color: white;
    padding: 14px 32px;
    outline: none;
    text-decoration: none;
  }
  .card .card-back a:hover {
    background-color: white;
    color: #0b0f08;
  }