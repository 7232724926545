.field {
    box-sizing: border-box;
    margin-bottom: 20px;
    width: 100%;
    border: 2px solid #eee;
    border-color: #f4f6f9;
    background: #ffffff;
    font-size: 13px;
    line-height: 1.2em;
    font-style: normal;
    padding: 1.347em 1.76em;
    color: #7f818d;
    text-decoration: none;
    text-transform: none;
    -webkit-appearance: none;
    outline: none;
    resize: none;
    transition: all 0.3s ease;
    vertical-align: baseline;
}

.field:focus {
    border-color: #04caf7;
}

.submit-button {
    -webkit-appearance: none;
    cursor: pointer;
    padding: 1.459em 3.41em;
    border: none;
    background: linear-gradient(to right, #0e0e12 50%, #47d6e8 50%) no-repeat scroll right bottom / 210% 100% #47d6e8 !important;
    font-family: "Roboto Serif", serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    transition: all 0.3s ease;
    outline: 0;
    border-radius: 23px;
}

.submit-button:hover {
    background: linear-gradient(to right, #0e0e12 50%, #47d6e8 50%) no-repeat scroll right bottom / 210% 100% #47d6e8 !important;
    background-position: left bottom !important;
    color: #ffffff;
}