.slide {
    position: relative;
    height: max-content;
}
.slide img {
    width: 100%;
    height: auto;
}
.slide::after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
    animation-fill-mode: forwards !important;
    animation: animate 2s;
    transition: ease-in;
    clip-path: inset(0px 0px 0px 0px);
    -webkit-clip-path: inset(0px 0px 0px 0px);
}
.slide::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-position: center !important;
    mix-blend-mode: screen;
}

@keyframes animate {
    0% {
        clip-path: circle(0px 0px 0px 0px);
        -webkit-clip-path: inset(0px 0px 0px 0px);
    }
    100% {
        clip-path: inset(0 0 0 100%);
        -webkit-clip-path: inset(0 0 0 100%);
    }
}