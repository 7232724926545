.panel1 {
    margin-bottom: 0 !important;
    padding-top: 2rem;
    padding-bottom: 5rem;
}

.panel1-bg-image {
    width: 100%;
    background-image: url('../../../../public/images/full_services_copy.png');
    background-size: cover;
    background-position: center !important;
}

.panel1 .left-panel {
    z-index: 2;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
}
.panel1 .left-panel>.inner {
    background-size: cover;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    box-sizing: border-box;
    background-image: url('../../../../public/images/bg_services.jpg');
}

.panel1 .right-panel {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    min-height: 100%;
}
.panel1 .right-panel>.inner {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    box-sizing: border-box;
    margin-top: 40px;
    margin-left: -55px;
    position: relative;
    background-color: #f4f6f9 !important;
    min-height: 100%;
}

@media only screen and (max-width: 576px) {
    .panel1 .right-panel>.inner {
        margin-top: 0 !important;
        margin-left: 0 !important;
    }
}