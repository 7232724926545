.loading * {
	border: 0;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
    
.loading:root {
	--hue: 223;
	--bg: hsl(var(--hue),10%,90%);
	--fg: hsl(223,10%,10%);
	font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
}
.loading {
	background: hsl(223,10%,90%);
	color: hsl(223,10%,10%);
	font: 1em/1.5 sans-serif;
	height: 100vh;
	display: grid;
	place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
}

.loading .pl {
	display: block;
	width: 6.25em;
	height: 6.25em;
}
.loading .pl__ring, .loading .pl__ball {
	animation: ring 2s ease-out infinite;
}
.loading .pl__ball {
	animation-name: ball;
}


/* Animation */
@keyframes ring {
	from {
		stroke-dasharray: 0 257 0 0 1 0 0 258;
	}
	25% {
		stroke-dasharray: 0 0 0 0 257 0 258 0;
	}
	50%, to {
		stroke-dasharray: 0 0 0 0 0 515 0 0;
	}
}
@keyframes ball {
	from, 50% {
		animation-timing-function: ease-in;
		stroke-dashoffset: 1;
	}
	64% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -109;
	}
	78% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -145;
	}
	92% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -157;
	}
	57%, 71%, 85%, 99%, to {
		animation-timing-function: ease-out;
		stroke-dashoffset: -163;
	}
}