.header-top-text {
    font-family: "PermanentMarker", cursive;
    font-size: 1.533rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: capitalize;
    letter-spacing: 0;
    margin: 0;
    outline: 0;
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
}
.header {
    background-image: url('../../../../public/images/Header.png');
    background-position: center;
    background-size: cover;
}
@media only screen and (max-width: 576px) {
    .logo-text {
        display: none;
    }
}
.header-text {
    font-family: "Roboto Serif", serif;
    font-size: 3.3360rem;
    font-weight: 600;
    font-style: normal;
    line-height: 1.2em;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    margin-top: 0em;
    margin-bottom: 0.67em;
    outline: 0;
    -webkit-font-smoothing: antialiased;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}