.section-2 {
    width: 100%;
    background-image: url('../../../../public/images/clipart-5.jpg');
    background-size: cover;
    background-position: center !important;
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.section-2 p {
    color: #fff;
}