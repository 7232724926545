
.text-container {
    background: #000;
    width: 100%;
    height: 30rem;
    font-weight: bold;
    font-size: 36px;
    position: relative;
    overflow: hidden;
}

.text-container > div {
    animation: come2life linear 10s infinite;
    transform-origin: center center;
    opacity: 0;
    width: 250px;
    height: 200px;
    position: absolute;
    backface-visibility: hidden;
    color: white;
}

.text-container > .child1 {
    left: 30%;
    top: 30%;
}

.text-container > .child2 {
    left: 70%;
    top: 30%;
}

.text-container > .child3 {
    left: 50%;
    top: 50%;
}

.text-container > .child4 {
    left: 30%;
    top: 70%;
}

.text-container > .child5 {
    left: 70%;
    top: 70%;
}

@keyframes come2life {
    0% {
        transform: scale3d(0,0,1) rotate(0.02deg);
        opacity: 0;
        filter: blur(10px);
    }
    25% {
        transform: scale3d(1,1,1) rotate(0.02deg);
        opacity: 0.24;
        filter: blur(0px);
    }
    40% {
        opacity: 1;
        filter: blur(2px);
    }

    80% {
      opacity: 0;
      filter: blur(10px);
    }
  
    100% {
        transform: scale3d(4,4,1) rotate(0.02deg);
        filter: blur(20px);
    }
}